<template>
  <div class="mt-2 mb-6">
    <v-alert
      v-if="errorMessage" 
      type="error"
      outlined
    >
      {{ errorMessage }}
    </v-alert>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: [String, Object],
  },
};
</script>