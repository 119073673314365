import {
  extend,
  ValidationProvider,
  ValidationObserver,
  localize,
} from "vee-validate";

// ルール設定
import * as rules from "vee-validate/dist/rules";

for (let rule in rules) {
  extend(rule, rules[rule]);
}
const passwordRule = {
  message:
    "英小文字・英大文字・数字・記号のうち3種類以上利用する必要があります",
  validate(value) {
    const lowercase = "(?=.*[a-z])";
    const uppercase = "(?=.*[A-Z])";
    const number = "(?=.*\\d)";
    const character =
      "(?=.*[!\"#$%&'()\\*\\+\\-\\.,\\/:;<=>?@\\[\\\\\\]^_`{|}~])";
    const pattern =
      "^(" +
      `${lowercase}${uppercase}${number}|${uppercase}${number}${character}|` +
      `${lowercase}${number}${character}|${lowercase}${uppercase}${character}` +
      ")(?=.*[a-zA-Z]).{3,}$";
    const reg = new RegExp(pattern);
    return value.match(reg);
  },
};
extend("password_rule", passwordRule);

// 日本語ファイルを読み込み
import ja from "vee-validate/dist/locale/ja.json";

// vee-validateの日本語化
localize("ja", ja);

export { ValidationProvider, ValidationObserver };