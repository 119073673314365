<template>
  <div>
    <v-row justify="end">
      <v-pagination
        v-model="currentPage"
        :length="Math.ceil(totalCount / itemsPerPage)"
        :total-visible="7"
        @input="pageSelected"
      />
    </v-row>
    <v-row>
      <v-col cols="12"> 
        <v-data-table
          :headers="tableHeaders(contents, dateContents)"
          :items="tableDatas(contents, dateContents)"
          :page.sync="currentPage"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
        >
          <template #[`item.pv`]="{ item }">
            {{ numberFormat(item.pv) }}
          </template>
          <template #[`item.uu`]="{ item }">
            {{ numberFormat(item.uu) }}
          </template>
          <template #[`item.num`]="{ item }">
            {{ numberFormat(item.num) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import numberFormat from "../utils/numberFormat";
export default {
  props: {
    contents: String,
    dateContents: String,
    data: Array,
    dateData: Array,
    monthData: Array,
    weekData: Array,
    timeData: Array,
    replyData: Array,
    page: Number,
    totalCount: Number,
  },
  data() {
    return {
      currentPage: 0,
      pageCount: 0,
      itemsPerPage: 15,
      tableHeader: []
    };
  },
  watch: {
    page: {
      immediate: true,
      handler(currentPage) {
        this.currentPage = currentPage;
      },
    },
  },
  methods: {
    tableHeaders(contents, dateContents) {
      if(contents == "dates") {
        if(dateContents == "day") {
          return this.tableHeader = [
            { text: "日時", align: "start", value: "data" },
            { text: "受信数(PV)", value: "num" },
          ];
        }
        else if(dateContents == "month") {
          return this.tableHeader = [
            { text: "月", align: "start", value: "data" },
            { text: "受信数(PV)", value: "num" },
          ];
        }
        else if(dateContents == "week") {
          return this.tableHeader = [
            { text: "曜日", align: "start", value: "data" },
            { text: "受信数(PV)", value: "num" },
          ];
        }
        else if(dateContents == "time") {
          return this.tableHeader = [
            { text: "時間", align: "start", value: "data" },
            { text: "受信数(PV)", value: "num" },
          ];
        }
      }
      else if(contents == "messages") {
        return this.tableHeader = [
          { text: "メッセージ", align: "start", value: "data" },
          { text: "受信数(PV)", value: "pv" },
          { text: "受信数(UU)", value: "uu" },
        ];
      }
      else if(contents == "replyKeywords") {
        return this.tableHeader = [
          { text: "受信日", align: "start", value: "date" },
          { text: "応答メッセージID", value: "reply_id" },
          { text: "タイトル", value: "title" },
          { text: "キーワード", value: "keyword" },
          { text: "受信数(PV)", value: "pv" },
          { text: "受信数(UU)", value: "uu" },
        ];
      }
    },

    tableDatas(contents) {
      if(contents == "dates") {
        switch(this.dateContents) {
        case "day":
          return this.dateData;
        case "month":
          return this.monthData;
        case "week":
          return this.weekData;
        case "time":
          return this.timeData;
        }
        return this.dateData;
      }
      else if(contents == "messages") {
        return this.data;
      }
      else if(contents == "replyKeywords") {
        return this.replyData;
      }
    },

    numberFormat(value) {
      return numberFormat(value);
    },

    pageSelected(selectedPage) {
      this.$emit("page-selected", selectedPage);
    }
  }
};

</script>