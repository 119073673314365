import Vue from "vue";
import Vuetify from "vuetify";
import VuetifyJa from "vuetify/lib/locale/ja";
import "@mdi/font/css/materialdesignicons.css";
import light from "./vuetify-theme";

Vue.use(Vuetify);
const opts = {
  lang: {
    locales: { VuetifyJa },
    current: "VuetifyJa",
  },
  theme: {
    themes: { light },
  },
};

export default new Vuetify(opts);
