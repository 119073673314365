import * as types from "@/store/mutation-types";

/**
 * vueの動的コンポーネントの仕様を利用して、
 * storeに入れたコンポーネント名のコンポーネントを動的に読み込んで表示
 * @type {{display: boolean, componentName: string}}
 */
const state = {
  display: false,
  width: null,
  componentName: "Logo",
  props: {},
  on: {},
};

const mutations = {

  [ types.DIALOG_SHOW ]( state, payload ) {
    state.display = true;
    state.width = payload.width || null;
    state.componentName = payload.componentName;
    state.props = payload.props;
    state.on = payload.on;
  },

  [ types.DIALOG_HIDE ]( state ) {
    state.display = false;
    state.width = null;
    state.componentName = "";
    state.props = {};
    state.on = {};
  },

};

const actions = {

  showDialog({ commit }, payload ) {
    commit( types.DIALOG_SHOW, payload );
  },

  hideDialog({ commit }) {
    commit( types.DIALOG_HIDE );
  },

};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};