import * as types from "@/store/mutation-types";

/**
 * vueの動的コンポーネントの仕様を利用して、
 * storeに入れたコンポーネント名のコンポーネントを動的に読み込んで表示
 * @type {{display: boolean, componentName: string}}
 */
const state = {
  display: false,
  componentName: "Logo",
  width: undefined,
  props: {},
};

const mutations = {

  [ types.DRAWER_SHOW ]( state, payload ) {
    state.display = true;
    state.componentName = payload.componentName;
    state.permanent = payload.permanent;
    state.width = payload.width;
    state.props = payload.props;
  },

  [ types.DRAWER_HIDE ]( state ) {
    state.display = false;
    state.componentName = "";
    state.permanent = false;
    state.width = undefined;
    state.props = {};
  },

};

const actions = {

  showDrawer({ commit }, payload ) {
    commit( types.DRAWER_SHOW, payload );
  },

  hideDrawer({ commit }) {
    commit( types.DRAWER_HIDE );
  },

};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
