export const generateUUID = () => {
  return crypto.randomUUID();
};

export const deepCopy = obj => {
  return JSON.parse( JSON.stringify( obj) );
};

export const isNumber = value => {
  return typeof value === "number" && !isNaN(value);

// console.log(isNumber(0));          // true
// console.log(isNumber(1.1));        // true
// console.log(isNumber('123'));      // false
// console.log(isNumber(null));       // false
// console.log(isNumber(undefined));  // false
// console.log(isNumber(NaN));        // false
};

export const toCamelCase = str => {
  return str
    .split("_") // アンダースコアで分割
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase(); // 最初の単語は小文字のまま
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // 残りの単語は最初の文字を大文字に
    })
    .join(""); // 結合して一つの文字列に
};
// const result = toCamelCase("hello_world_example");
// console.log(result); // "helloWorldExample"