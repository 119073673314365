<template>
  <div>
    <!-- 「未入力項目があります」Modal -->
    <div :class="`modal ${incompleteModalStatus}`">
      <div
        class="close_modal"
        @click="setVisibility(false)"
      >
        ×
      </div>
      <div class="modal-background" />
      <div class="modal-content has-background-white">
        <div class="content">
          <div class="block mt-5">
            <h1 class="has-text-centered">
              未入力項目があります
            </h1>
          </div>
          <div class="block incomplete_items has-text-left">
            <div>未入力項目</div>
            <ul>
              <li
                v-for="item in getEmptyItemList"
                :key="item.key"
              >
                <a>{{ item.name }}</a>
              </li>
            </ul>
          </div>
          <div class="has-text-centered mb-3">
            <button
              class="button button_bgcolor_info has-text-white incomplete_items"
              @click="footerButtonClicked(false)"
            >
              編集画面へ
            </button>
          </div>
          <div class="has-text-centered mb-3">
            <button
              class="button button_bgcolor_light incomplete_items"
              @click="footerButtonClicked(true)"
            >
              無視して保存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
      required: false,
    }
  },
  data() {
    return {
      // モーダルやドロップダウンのステータス(is-activeかどうか)
      incompleteModalStatus: "",
    };
  },

  // created(): {},
  // mounted(): {},
  // watch: {},

  computed: {
    getEmptyItemList() {
      return this.items;
    }
  },

  methods: {
    // モーダルの表示/非表示切り替え
    setVisibility(value) {
      this.incompleteModalStatus = value ? "is-active" : "";
    },

    // 「強制的に保存」クリック時の処理
    footerButtonClicked(isSave) {
      // 「強制的に保存」の場合のみ、親に伝える
      if (isSave) this.$emit("emitted");
      this.setVisibility(false);
    },
  }
};
</script>

<style scoped>
.button_bgcolor_info {
  background-color: #2F80ED;
}

.button_bgcolor_light {
  background-color: #F6F6F6;
}

.modal .modal-content .button {
  border-radius: 4px;
}

.modal .modal-content {
  width: 456px;
  border-radius: 4px;
}

.modal .modal_template, .modal .modal_draft, .modal .modal_file {
  width: 1065px;
  border-radius: 8px;
}

.modal .update_contents .button {
  width: 300px;
}

/* モーダル右上の「×」ボタン */
.modal .close_modal {
  width: 30px;
  height: 30px;
  top: 0;
  left: 228px;
  text-align: center;
  background-color:rgba(255, 255, 255, 1);
  border-radius: 20px;
  z-index: 2147483647;
  font-weight: bold;
  transform: translateY(15px);
  position: relative;
  box-shadow: -0.25px 0.25px 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.incomplete_items {
  width: 60%;
  margin: 0 auto;
}

</style>
