import * as types from "@/store/mutation-types";
import {
  getPlanGrade,
  isLowerThanStarter,
  isLowerThanLight,
  isLowerThanStandard,
  isLowerThanPro,
  isLowerThanEnterprise
} from "@/utils/account";

/**
 * @fileoverview vueの動的コンポーネントの仕様を利用して、
 * storeに入れたコンポーネント名のコンポーネントを動的に読み込んで表示
 */

/**
 * [grede] 0:フリー, 1: ライト, 2:スタンダード, 3: プロ, 4:エンタープライズ, 5: スターター
 * @type {{name: string, grade: '0'|'1'|'2'|'3'|'4'|'5', max_delivery: number}}
 */
const plan = {
  name: "",
  grade: "",
  max_delivery: 0,
};

/** 
 * @type {{ plan: object } 
 * */
const state = {
  plan: plan,
  point_code_enable: false,
};

const getters = {
  getGradeName( state ) {
    return getPlanGrade(state.plan.grade);
  },
  isLowerThanStarter( state ) {
    return isLowerThanStarter(state.plan.grade);
  },
  isLowerThanLight( state ) {
    return isLowerThanLight(state.plan.grade);
  },
  isLowerThanStandard( state ) {
    return isLowerThanStandard(state.plan.grade);
  },
  isLowerThanPro( state ) {
    return isLowerThanPro(state.plan.grade);
  },
  isLowerThanEnterprise( state ) {
    return isLowerThanEnterprise(state.plan.grade);
  },
};

const mutations = {

  [types.ACCOUNT_PLAN_SET]( state, payload ) {
    state.plan.name = payload.name;
    state.plan.grade = payload.grade;
    state.plan.max_delivery = payload.max_delivery;
  },

  [types.ACCOUNT_POINT_CODE_ENABLE_SET]( state, payload ) {
    state.point_code_enable = payload.point_code_enable;
  }

};

const actions = {
  /** [payload] data.plan */
  setAccountPlan({ commit }, payload ){
    commit( types.ACCOUNT_PLAN_SET, payload );
  },
  /** [payload] data.currentAccount */
  setAccountPointCodeEnable({ commit }, payload ){
    commit( types.ACCOUNT_POINT_CODE_ENABLE_SET, payload );
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
