<template>
  <v-footer
    class="caption pb-6"
    color="white"
  >
    <v-btn
      small
      text
      color="grey darken-2"
      href="https://help.tsunagaru.app/"
      target="_blank"
    >
      使い方
    </v-btn>
    <v-btn
      small
      text
      color="grey darken-2"
      href="https://www.tsunagaru.app/contact"
      target="_blank"
    >
      お問い合わせ
    </v-btn>
    <v-btn
      small
      text
      color="grey darken-2"
      href="https://www.tsunagaru.app/terms"
      target="_blank"
    >
      利用規約
    </v-btn>
    <v-spacer />
    <div class="d-flex align-center">
      <TsuIcon size="XS">
        mdi-copyright
      </TsuIcon>
      <span class="ml-1">OPT, Inc. All Rights Reserved.</span>
    </div>
  </v-footer>
</template>

<script>
import TsuIcon from "@/components/atoms/TsuIcon.vue";
export default {
  components: {
    TsuIcon,
  }
};
</script>