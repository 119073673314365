import Vue from "vue";
import VueRouter from "vue-router";
const DeliveriesList = () => import("./src/pages/deliveriesList");
const Login = () => import("./src/pages/login");
const Signup = () => import("./src/pages/signup");
const TwoFactorAuth = () => import("./src/pages/twoFactorAuth");
const EditUser = () => import("./src/pages/editUser");
const PasswordReset = () => import("./src/pages/passwordReset");
const PasswordChange = () => import("./src/pages/passwordChange");
const CreateWorkspace = () => import("./src/pages/createWorkspace");
const JoinWorkspace = () => import("./src/pages/joinWorkspace");
const SelectAccount = () => import("./src/pages/selectAccount");
const SettingsCompanyApiSettings = () => import("./src/pages/settings/company_api_settings/index");
const SettingsExternalApiSettings = () => import("./src/pages/settings/external_api_settings/index");
const SettingsTestDevices = () => import("./src/pages/settingsTestDevices");
const SettingsUsers = () => import("./src/pages/settingsUsers");
const SettingsLineCoordination = () => import("./src/pages/settingsLineCoordination");
const SettingsWorkspace = () => import("./src/pages/settingsWorkspace");
const SettingsPlanChanges = () => import("./src/pages/settingsPlanChanges");
const EditPlan = () => import("./src/pages/editPlan");
const SettingsInvoices = () => import("./src/pages/settingsInvoices");
const ContentList = () => import("./src/pages/contentList");
const StampForm = () => import("./src/pages/stampForm");
const LocationForm = () => import("./src/pages/locationForm");
const Questionnaires = () => import("./src/pages/questionnaires/");
const MediaForm = () => import("./src/pages/mediaForm");
const RichmenuContentForm = () => import("./src/pages/richmenuContentForm");
const ClickUrlsForm = () => import("./src/pages/clickUrlsForm");
const NormalText = () => import("./src/pages/normalText");
const RichVideo = () => import("./src/pages/richVideo");
const CreateButtonMessage = () => import("./src/pages/createButtonMessage");
const CreateConfirmMessage = () => import("./src/pages/createConfirmMessage");
const CreateRichMessage = () => import("./src/pages/createRichMessage");
const CreateCarouselCreative = () => import("./src/pages/createCarouselCreative");
const CreateImageCarouselCreative = () => import("./src/pages/createImageCarouselCreative");
const Lists = () => import("./src/pages/lists");
const NewList = () => import("./src/pages/newList");
const EditList = () => import("./src/pages/editList");
const CreateDelivery = () => import("./src/pages/createDelivery");
const WelcomeMessagesList = () => import("./src/pages/welcomeMessagesList");
const CreateWelcomeMessage = () => import("./src/pages/createWelcomeMessage");
const EditWelcomeMessage = () => import("./src/pages/editWelcomeMessage");
const AutoMessagesList = () => import("./src/pages/autoMessagesList");
const CreateAutoMessage = () => import("./src/pages/createAutoMessage");
const EditAutoMessage = () => import("./src/pages/editAutoMessage");
const RichmenuList = () => import("./src/pages/richmenuList");
const RichmenuForm = () => import("./src/pages/richmenuForm");
const RichmenuBulkInsert = () => import("./src/pages/richmenuBulkInsert");
const TriggerMessagesList = () => import("./src/pages/triggerMessagesList");
const CreateTriggerMessage = () => import("./src/pages/createTriggerMessage");
const ExternalMessagesList = () => import("./src/pages/externalMessagesList");
const CreateExternalMessage = () => import("./src/pages/createExternalMessage");
const CampaignLists = () => import("./src/pages/campaignLists");
const PointCodeGroupForm = () => import("./src/pages/pointCodeGroupForm");
const AnalyzeDeliveries = () => import("./src/pages/analyzeDeliveries");
const AnalyzeDeliveriesChart = () => import("./src/pages/analyzeDeliveriesChart");
const AnalyzeDeliveriesDetail = () => import("./src/pages/analyzeDeliveriesDetail");
const AnalyzeContents = () => import("./src/pages/analyzeContents");
const AnalyzeClickUrls = () => import("./src/pages/analyzeClickUrls");
const AnalyzeDeliveryLists = () => import("./src/pages/analyzeDeliveryLists");
const AnalyzeMessages = () => import("./src/pages/analyzeMessages");
const AnalyzeFriends = () => import("./src/pages/analyzeFriends");
const AnalyzeRichmenus = () => import("./src/pages/analyzeRichmenus");
const AnalyzeRichmenusDetail = () => import("./src/pages/analyzeRichmenusDetail");
const AnalyzeScenariosDetail = () => import("./src/pages/analyzeScenariosDetail");
const Invitation = () => import("./src/pages/invitation");
const AdminDashboard = () => import("./src/pages/adminDashboard");
const AdminEnterpriseForm = () => import("./src/pages/adminEnterpriseForm");
const AdminCampaignAnswersForm = () => import("./src/pages/adminCampaignAnswersForm");
const ContentsProperties = () => import("./src/pages/contentsProperties");

Vue.use(VueRouter);

const routes = [
  { path: "/", component: DeliveriesList },
  { path: "/sessions", component: Login, meta: { blankLayout: true } },
  /** 直接表示されることはないかもしれないが、アクセスできてしまうため念の為定義 */
  { path: "/sessions/new", component: Login, meta: { blankLayout: true } },
  { path: "/login", component: Login, meta: { blankLayout: true } },
  { path: "/sessions/two_factor_authentications", component: TwoFactorAuth, meta: { blankLayout: true } },
  { path: "/signup", component: Signup, meta: { blankLayout: true } },
  { path: "/users/edit", component: EditUser, meta: { blankLayout: true } },
  { path: "/password_resets/new", component: PasswordReset, meta: { blankLayout: true } },
  { path: "/password_resets/:id/edit", component: PasswordChange, meta: { blankLayout: true } },
  { path: "/workspaces/new", component: CreateWorkspace, meta: { blankLayout: true } },
  { path: "/workspaces", component: JoinWorkspace, meta: { blankLayout: true } },
  { path: "/accounts/select", component: SelectAccount, meta: { blankLayout: true } },
  { path: "/settings/company_api_settings", component: SettingsCompanyApiSettings },
  { path: "/settings/external_api_settings", component: SettingsExternalApiSettings },
  { path: "/settings/test_devices", component: SettingsTestDevices },
  { path: "/settings/users", component: SettingsUsers },
  { path: "/settings/line_coordination", component: SettingsLineCoordination },
  { path: "/settings/workspaces", component: SettingsWorkspace },
  { path: "/settings/plan_changes", component: SettingsPlanChanges },
  { path: "/settings/plan_changes/:id/edit", component: EditPlan },
  { path: "/settings/invoices", component: SettingsInvoices },
  { path: "/contents", component: ContentList },
  { path: "/contents/stamps/new", component: StampForm },
  { path: "/contents/stamps/:id/edit", component: StampForm },
  { path: "/contents/locations/new", component: LocationForm },
  { path: "/contents/locations/:id/edit", component: LocationForm },
  { path: "/contents/questionnaires/new", component: Questionnaires },
  { path: "/contents/questionnaires/:id/edit", component: Questionnaires },
  { path: "/contents/media_contents/new", component: MediaForm },
  { path: "/contents/media_contents/:id/edit", component: MediaForm },
  { path: "/contents/richmenus/new", component: RichmenuContentForm },
  { path: "/contents/richmenus/:id/edit", component: RichmenuContentForm },
  { path: "/contents/click_urls/new", component: ClickUrlsForm },
  { path: "/contents/texts/normal_contents/new", component: NormalText },
  { path: "/contents/texts/normal_contents/:id/edit", component: NormalText },
  { path: "/contents/rich_contents/rich_videos/new", component: RichVideo },
  { path: "/contents/rich_contents/rich_videos/:id/edit", component: RichVideo },
  { path: "/contents/rich_contents/button_message/new", component: CreateButtonMessage },
  { path: "/contents/rich_contents/button_message/:id/edit", component: CreateButtonMessage },
  { path: "/contents/rich_contents/confirm_message/new", component: CreateConfirmMessage },
  { path: "/contents/rich_contents/confirm_message/:id/edit", component: CreateConfirmMessage },
  { path: "/contents/rich_contents/rich_message/new", component: CreateRichMessage },
  { path: "/contents/rich_contents/rich_message/:id/edit", component: CreateRichMessage },
  { path: "/contents/rich_contents/carousel_creatives/new", component: CreateCarouselCreative },
  { path: "/contents/rich_contents/carousel_creatives/:id/edit", component: CreateCarouselCreative },
  { path: "/contents/rich_contents/image_carousel_creatives/new", component: CreateImageCarouselCreative },
  { path: "/contents/rich_contents/image_carousel_creatives/:id/edit", component: CreateImageCarouselCreative },
  { path: "/contents/properties/new", component: ContentsProperties },
  { path: "/lists", component: Lists },
  { path: "/lists/new", component: NewList },
  { path: "/lists/:id/copy", component: NewList },
  { path: "/lists/:id/edit", component: EditList },
  { path: "/deliveries", component: DeliveriesList },
  { path: "/deliveries/new", component: CreateDelivery },
  { path: "/deliveries/:id/edit", component: CreateDelivery },
  { path: "/deliveries/welcome_messages", component: WelcomeMessagesList },
  { path: "/deliveries/welcome_messages/new", component: CreateWelcomeMessage },
  { path: "/deliveries/welcome_messages/:id/edit", component: EditWelcomeMessage },
  { path: "/deliveries/auto_messages", component: AutoMessagesList },
  { path: "/deliveries/auto_messages/new", component: CreateAutoMessage },
  { path: "/deliveries/auto_messages/:id/edit", component: EditAutoMessage },
  { path: "/deliveries/richmenus", component: RichmenuList },
  { path: "/deliveries/richmenus/bulk_inserts/new", component: RichmenuBulkInsert },
  { path: "/deliveries/richmenus/new", component: RichmenuForm },
  { path: "/deliveries/richmenus/:id/edit", component: RichmenuForm },
  { path: "/deliveries/trigger_messages", component: TriggerMessagesList },
  { path: "/deliveries/trigger_messages/new", component: CreateTriggerMessage },
  { path: "/deliveries/trigger_messages/:id/edit", component: CreateTriggerMessage },
  { path: "/deliveries/external_messages", component: ExternalMessagesList },
  { path: "/deliveries/external_messages/new", component: CreateExternalMessage },
  { path: "/deliveries/external_messages/:editId/edit", component: CreateExternalMessage, props: true },
  { path: "/campaigns", component: CampaignLists },
  { path: "/point_code_groups/new", component: PointCodeGroupForm },
  { path: "/point_code_groups/:id/edit", component: PointCodeGroupForm },
  { path: "/analytics/deliveries", component: AnalyzeDeliveries },
  { path: "/analytics/deliveries/chart", component: AnalyzeDeliveriesChart },
  { path: "/analytics/deliveries/:id/detail", component: AnalyzeDeliveriesDetail, props: true },
  { path: "/analytics/contents", component: AnalyzeContents },
  { path: "/analytics/richmenus", component: AnalyzeRichmenus },
  { path: "/analytics/richmenus/:id/detail", component: AnalyzeRichmenusDetail, props: true},
  { path: "/analytics/click_urls", component: AnalyzeClickUrls },
  { path: "/analytics/lists", component: AnalyzeDeliveryLists },
  { path: "/analytics/received_messages", component: AnalyzeMessages },
  { path: "/analytics/friends", component: AnalyzeFriends },
  { path: "/analytics/scenarios/:id/detail", component: AnalyzeScenariosDetail, props: true },
  { path: "/invitation", component: Invitation, meta: { blankLayout: true } },
  { path: "/admin", component: AdminDashboard, meta: { blankLayout: true } },
  { path: "/admin/plan_changes/:id/edit", component: AdminEnterpriseForm, meta: { blankLayout: true } },
  { path: "/admin/campaign_answers", component: AdminCampaignAnswersForm, meta: { blankLayout: true } }
];

export default new VueRouter({ mode: "history", routes });
