export const ERROR_SET = "ERROR_SET";
export const ERROR_RESET = "ERROR_RESET";

export const ACCOUNT_PLAN_SET = "ACCOUNT_PLAN_SET";
export const ACCOUNT_POINT_CODE_ENABLE_SET = "ACCOUNT_POINT_CODE_ENABLE_SET";

export const DIALOG_SHOW = "DIALOG_SHOW";
export const DIALOG_HIDE = "DIALOG_HIDE";

export const SNACKBARS_SHOW = "SNACKBARS_SHOW";
export const SNACKBARS_HIDE = "SNACKBARS_HIDE";

export const OVERLAY_SHOW = "OVERLAY_SHOW";
export const OVERLAY_HIDE = "OVERLAY_HIDE";

export const DRAWER_SHOW = "DRAWER_SHOW";
export const DRAWER_HIDE = "DRAWER_HIDE";

export const QNNAIRE_DATA_SET = "QNNAIRE_DATA_SET";
export const QNNAIRE_DESIGN_TEMPLATE_SET = "QNNAIRE_DESIGN_TEMPLATE_SET";
export const SET_QUESTION_INDEX = "SET_QUESTION_INDEX";
export const UPDATE_QUESTIONS = "UPDATE_QUESTIONS";
export const UPDATE_QUESTION_BRANCH = "UPDATE_QUESTION_BRANCH";
export const TOGGLE_QNNAIRE_HEADER = "TOGGLE_QNNAIRE_HEADER";