<template>
  <v-menu
    :close-on-content-click="false"
    offset-x
  >
    <template #activator="{ on, attrs }">
      <v-btn
        text
        v-bind="attrs"
        style="text-transform: none"
        v-on="on"
      >
        {{ currentAccount.name }}
        <v-icon
          small
          right
        >
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-card elevation="1">
      <v-list-group
        v-for="(workspace, key) in accountList"
        :key="workspace[0].workspace_id"
        no-action
      >
        <template #activator>
          <v-list-item-title class="text-subtitle-2">
            {{ key }}
          </v-list-item-title>
        </template>
        <v-list-item
          v-for="account in workspace"
          :key="`account-${account.account_id}`"
          to=""
          link
          dense
        >
          <v-list-item-content @click="setAccount(account.account_id, account.workspace_id)">
            <v-list-item-title class="text-caption">
              {{ account.account_name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-divider />
      <v-card-actions>
        <v-list-item dense>
          <v-row
            algin="center"
            justify="end"
          >
            <v-btn
              text
              x-small
              @click="linkTo('/settings/line_coordination')"
            >
              <v-icon
                left
                color="grey lighten-1"
              >
                mdi-cog-outline
              </v-icon>
              LINEアカウント連携設定
            </v-btn>
          </v-row>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    currentAccount: {
      type: Object,
      required: true,
    },
    accountList: {
      type: Object,
      required: true,
    }
  },
  methods: {
    setAccount: function(accountId, workspaceId) {
      this.$axios
        .post(`/accounts/set?account_id=${accountId}&workspace_id=${workspaceId}`)
        .then(() => {
          window.location.href = "/";
        })
        .catch((err) => {
          console.log(err);
          alert("アカウント切り替えできせんでした。");
        });
    },
    addAccount() {
      window.location.href = "/accounts/new";
    },
    linkTo( link ) {
      return location.href = link;
    },
  },
};
</script>