<template>
  <div>
    <v-app-bar 
      color="white"
      elevation="0"
    >
      <v-toolbar-title
        class="px-2"
      >
        <Logo />
      </v-toolbar-title>
      <div class="ml-4">
        <LayoutAccountsMenu
          :current-account="currentAccount"
          :account-list="accountList"
        />
      </div>
      <v-spacer />
      <LayoutProfileMenu :current-user-name="currentUserName" />
    </v-app-bar>

    <v-main>
      <v-sheet
        class="d-flex"
      >
        <v-sheet
          width="250px"
          color="#EEFAFA"
        >
          <LayoutGlobalMenu />
        </v-sheet>
        <v-sheet
          width="85%"
          min-height="94vh"
          class="d-flex flex-column justify-space-between"
        >
          <v-sheet
            class="py-4 px-8"
          >
            <slot />
          </v-sheet>
          <v-sheet>
            <v-divider />
            <LayoutFooter />
          </v-sheet>
        </v-sheet>
      </v-sheet>
        
      <!-- ダイアログ表示用 -->
      <v-dialog
        v-model="dialog.display"
        :max-width="dialog.width || '650px'"
      >
        <div class="white pa-4">
          <component
            :is="dialog.componentName"
            v-bind="dialog.props"
            v-on="dialog.on"
          />
        </div>
      </v-dialog>
    </v-main>

    <v-navigation-drawer
      v-model="drawer.display"
      app
      fixed
      temporary
      right
      color="grey lighten-4"
      :width="drawer.width || '45%'"
      :permanent="drawer.permanent"
      style="zIndex: 11;"
      @input="changeDrawer"
    >
      <component
        :is="drawer.componentName"
        v-bind="drawer.props"
      />
    </v-navigation-drawer>

    <v-overlay
      :value="overlay.display"
      z-index="99999"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </v-overlay>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Logo from "@/components/logo";
import LayoutGlobalMenu from "@/shared/components/layout/layoutGlobalMenu";
import LayoutAccountsMenu from "@/shared/components/layout/layoutAccountsMenu";
import LayoutProfileMenu from "@/shared/components/layout/layoutProfileMenu";
import LayoutFooter from "@/shared/components/layout/layoutFooter";
import dynamicModals from "@/utils/dynamicModals";
import dynamicDrawers from "@/utils/dynamicDrawers";

export default {
  components: {
    Logo,
    LayoutGlobalMenu,
    LayoutAccountsMenu,
    LayoutProfileMenu,
    LayoutFooter,
    ...dynamicModals,
    ...dynamicDrawers,
  },

  data(){
    return {
      accountList: {},
      currentAccount: {},
      currentUserName: "",
    };
  },

  computed: {
    // ダイアログの表示・非表示、コンポーネント名・プロパティを取得
    ...mapState(["dialog", "drawer", "overlay"])
  },

  async created() {
    const res = await this.fetchAccounts();
    this.accountList = res.data.accountList;
    this.currentAccount = res.data.currentAccount;
    this.currentUserName = res.data.currentUserName;
    this.setAccountPlan(res.data.plan);
    this.setAccountPointCodeEnable(res.data.currentAccount);
  },

  methods: {
    fetchAccounts() {
      return this.$axios.get("/api/accounts/account_list_by_workspace");
    },
    changeDrawer(value) {
      // 閉じるボタンではなく枠外をクリックして閉じると
      // drawerの他のstateが初期化されないのでここで初期化を実行
      if (!value) {
        this.hideDrawer();
      }
    },
    ...mapActions( "account", [ "setAccountPlan", "setAccountPointCodeEnable" ]),
    ...mapActions( "drawer", [ "hideDrawer" ] ),
  }
};
</script>

<style lang="scss" scoped>
  .v-main {
    border-top: 1px solid #e5e5e5;
  }
</style>