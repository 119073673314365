const dynamicModals = {
  CardSuggestUpgrade: () => import("@/components/CardSuggestUpgrade"),
  InviteHistoryModal: () => import("@/models/settings/users/components/inviteHistoryModal"),
  CardNotification: () => import("@/components/CardNotification"),
  CardConfirmDelete: () => import("@/components/CardConfirmDelete"),
  CardCreateAndEditTestDevice: () => import("@/models/settings/test_devices/components/cardCreateAndEditTestDevice"),
  CardLinkDevice: () => import("@/models/settings/test_devices/components/cardLinkDevice"),
  CardTableSettings: () => import("@/components/CardTableSettings"),
  CardSelectDeliveries: () => import("@/components/CardSelectDeliveries"),
  CardSelectContents: () => import("@/components/CardSelectContents"),
  CardSelectLists: () => import("@/components/CardSelectLists"),
  CardFailedListDelete: () => import("@/components/CardFailedListDelete"),
  CardConfirmMultiDelete: () => import("@/components/CardConfirmMultiDelete"),
  CardLayoutLists: () => import("@/models/richmenu_content/layouts.vue"),
  CardRichmenuBulkUpdateModal: () => import("@/models/richmenu/bulkUpdateDialog.vue"),
  DesignTemplateModal: () => import("@/models/questionnaires/components/modals/designTemplateModal.vue"),
  CreateClickUrlModal: () => import("@/shared/components/clickUrlCreate.vue"),
  SaveConfirmModal: () => import("@/models/questionnaires/components/modals/saveConfirmModal.vue"),
  TwoFactorAuth: () => import("@/models/user/twoFactorAuth"),
};

export default dynamicModals;
