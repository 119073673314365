<template>
  <div>
    <!-- ヘッダー・サイドバーありのレイアウト -->
    <LayoutMain v-if="isLayoutMain">
      <slot />
    </LayoutMain>
    <!-- 空のレイアウト -->
    <LayoutBlank v-else>
      <slot />
    </LayoutBlank>
  </div>
</template>

<script>

import LayoutMain from "@/components/layouts/layoutMain";
import LayoutBlank from "@/components/layouts/layoutBlank";

export default {
  components: {
    LayoutMain,
    LayoutBlank,
  },
  computed: {
    isAdminPath() {
      /**
       * adminの画面でrails側で描画しているページがあるため、
       * そのページにレイアウトが当たらないようにこの判定は残しておく
       */
      const paths = this.$route.path.split("/");
      return paths[1] === "admin";
    },

    isLayoutMain() {
      return !this.isAdminPath && !(this.$route.meta && this.$route.meta.blankLayout);
    },
  },
};
</script>