import * as types from "@/store/mutation-types";
import { initData } from "@/models/questionnaires/utils/initData";
import { deepCopy, toCamelCase } from "@/utils/helpers";
import { formartQuestion, reorderEnableQuestions, setBranchParents } from "@/models/questionnaires/utils/cleanupQuestions";

const state = {
  data: initData,
  workingQuestionIndex: 0, //作業中の設問
  displayFixHeader: false,
};

const getters = {
  qnnaireStatus( state ){
    // "publish", "draft"
    return state.data.status;
  },
  questions(){
    return state.data.questionnaires;
  },
  enableQuestions( state ){
    let q = [];
    for ( let i = 0; i < state.data.questionnaires.length; i++ ) {
      if ( state.data.questionnaires[i].is_enable ) q.push(state.data.questionnaires[i]);
    }
    return q;
  },
  archivedQuestions( state ){
    let q = [];
    for ( let i = 0; i < state.data.questionnaires.length; i++ ) {
      if ( !state.data.questionnaires[i].is_enable ) q.push(state.data.questionnaires[i]);
    }
    return q;
  },

  useableQuestionsForBranching: ( state, getters ) => index => {
    // 条件分岐に設定可能な設問
    const eqSlice = getters.enableQuestions.slice( index + 1 );
    return eqSlice.filter( eq => eq.kind === "question" );
  },
  getQustionByIndex: ( state ) => index => {
    return state.data.questionnaires[index] || null;
  },
  indexQuestions ( state ) {
    let q = [];
    for ( let i = 0; i < state.data.questionnaires.length; i++ ) {
      if ( state.data.questionnaires[i].is_enable && state.data.questionnaires[i].kind === "question" ) {
        q.push(state.data.questionnaires[i].branch.uid);
      }
    }
    return q;
  },
  submitQuestionsData( state ){
    const data = [];
    for ( let qi = 0; qi < state.data.questionnaires.length; qi++ ) {
      data.push( formartQuestion( state.data.questionnaires[qi]) );
    }
    return data;
  },
  submitQnnaireDesignData( state ) {
    return {
      pattern: state.data.pattern,
      string_color_index: state.data.string_color_index,
      string_color_rgb: state.data.string_color_rgb,
      background_color_index: state.data.background_color_index,
      background_color_rgb: state.data.background_color_rgb,
      accent_color_index: state.data.accent_color_index,
      accent_color_rgb: state.data.accent_color_rgb,
    };
  },
};

const mutations = {
  [types.QNNAIRE_DATA_SET]( state, payload ) {
    state.data = payload;
  },
  [types.QNNAIRE_DESIGN_TEMPLATE_SET]( state, payload ) {
    state.data.string_color_index      = payload.stringColor.index;
    state.data.string_color_rgb        = payload.stringColor.rgb;
    state.data.background_color_index  = payload.backgroundColor.index;
    state.data.background_color_rgb    = payload.backgroundColor.rgb;
    state.data.accent_color_index      = payload.accentColor.index;
    state.data.accent_color_rgb        = payload.accentColor.rgb;
    state.data.pattern                 = payload.pattern.index;
  },

  [types.SET_QUESTION_INDEX]( state, payload ) {
    state.workingQuestionIndex = payload;
  },

  [types.UPDATE_QUESTIONS]( state, payload ) {
    state.data.questionnaires = payload;
  },

  [types.UPDATE_QUESTION_BRANCH]( state, payload ) {
    state.data.questionnaires = payload;
  },
  [types.TOGGLE_QNNAIRE_HEADER]( state, payload ) {
    state.displayFixHeader = payload;
  }
};

const actions = {
  setQnnaireData({ commit }, payload ){
    commit( types.QNNAIRE_DATA_SET, payload );
  },

  setQnDesignTemplate({ commit }, payload ){
    commit( types.QNNAIRE_DESIGN_TEMPLATE_SET, payload );
  },

  setQuestionIndex({ commit }, { index }) {
    commit( types.SET_QUESTION_INDEX, index );
  },

  updateQnnaireData({ commit, state }, { params }){
    const data = deepCopy( state.data );
    Object.keys( params ).forEach( key => {
      // プロパティ名が存在したら更新
      if ( Object.hasOwn( data, key )) data[key] = params[key];
    });
    commit( types.QNNAIRE_DATA_SET, data );
  },

  addQuestion({ commit, getters }, { question }) {
    const questions = deepCopy( getters.questions );
    questions.push( question );
    commit( types.UPDATE_QUESTIONS, questions );
  },

  deleteQuestion({ commit, getters }, { id }){
    const questions_c = deepCopy( getters.questions );
    const filterQuestions = questions_c.filter( q => q.id !== id );
    commit( types.UPDATE_QUESTIONS, reorderEnableQuestions( filterQuestions ) );
  },

  updateQuestions({ commit }, payload ) {
    const questions_c = deepCopy( payload );
    commit( types.UPDATE_QUESTIONS, reorderEnableQuestions( questions_c ) );
  },

  // 設問情報の更新
  updateQuestion({ commit, getters }, { qid, params }){
    // 更新したいプロパティに階層がある場合はこの関数以外を利用 ( questionnaire_choicesなど )
    if ( !params ) throw new Error("updateQuestion: No params");
    let questions = deepCopy( getters.questions );
    questions.map( q => {

      // 対象のidを持つ設問の情報を更新
      if ( q.id === qid ) {
        Object.keys( params ).forEach( key => {
          // プロパティ名が存在したら更新
          if ( Object.hasOwn( q, key )) q[key] = params[key];
        });
      }
    });

    const questions_c = deepCopy( questions );
    commit( types.UPDATE_QUESTIONS, reorderEnableQuestions( questions_c ) );

  },

  // 選択肢の変更
  updateQuestionChoices({ commit, getters }, { qid, questionChoices } ){
    const questions_c = deepCopy( getters.questions );
    questions_c.forEach( q => {
      if ( q.id === qid ) q.questionnaire_choices = questionChoices;
    });
    const questions_c_b = setBranchParents( questions_c );
    commit( types.UPDATE_QUESTIONS, reorderEnableQuestions( questions_c_b ));
  },

  updateQuestionBranch({ commit, getters }, payload ) {
    const { qid, cid, branches } = payload;
    const questions_c = deepCopy( getters.questions );
    questions_c.forEach( q => {
      if ( q.id === qid ) {
        q.questionnaire_choices.forEach( qc => {
          if ( qc.id === cid )
            qc.branches = branches;
        });
      }
    });
    // 分岐元の設問情報を更新 ( question.branchParents )
    const questions_c_b = setBranchParents( questions_c );
    commit( types.UPDATE_QUESTIONS, reorderEnableQuestions( questions_c_b ));
  },
  
  archivedQuestion({ commit, getters }, { qid }) {
    const questions_c = deepCopy( getters.questions );
    let target_index = null;
    for ( let qi = 0; qi < questions_c.length; qi++ ) {
      if ( qid === questions_c[qi].id ) {
        questions_c[qi].is_enable = false;
        questions_c[qi].order = 99;
        questions_c[qi].branch.index = "";
        questions_c[qi].branchParents = [];
        questions_c[qi].questionnaire_choices.forEach( qc =>{
          qc.branches = [];
        });
        target_index = qi;
      }
    }
    // アーカイブ対象を一度抜き取って末尾に追加
    const target = questions_c.splice( target_index, 1 )[0];
    questions_c.push( target );
    commit( types.UPDATE_QUESTIONS, reorderEnableQuestions( questions_c ));
  },
  toggleFixHeader({ commit }, { status }){
    commit( types.TOGGLE_QNNAIRE_HEADER, status );
  },
};


export default {
  state,
  getters,
  mutations,
  actions
};