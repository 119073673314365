<template>
  <div @click="clearFlashMessage">
    <div :class="`flash js-messagejs js-close-parent ${flashMessageStatus}`">
      <div class="flash__inner">
        <div class="flash__message">
          <ul
            v-if="Array.isArray(flashMessage)"
            class="flash-messages-ul"
          >
            <li
              v-for="message in flashMessage"
              :key="message"
            >
              {{ message }}
            </li>
          </ul>
          <ul
            v-else
            class="flash-messages-ul"
          >
            <li>
              {{ flashMessage }}
            </li>
          </ul>
        </div>
        <div class="flash__close-icon js-close-parent__trigger">
          <i
            class="v-icon notranslate mdi mdi-close theme--light"
            style="font-size: 16px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialFlashMessage: {
      type: [String, Array],
      require: true,
      default: ""
    },
    initialFlashMessageStatus: {
      type: String,
      require: true,
      default: ""
    },
  },
  data() {
    return {
      flashMessage: this.initialFlashMessage,
      flashMessageStatus: this.initialFlashMessageStatus,
    };
  },
  methods: {
    clearFlashMessage() {
      this.flashMessage = "";
      this.$emit("clear");
    },
  }
};
</script>
