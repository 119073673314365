import * as types from "@/store/mutation-types";

const state = {
  display: false,
};

const mutations = {

  [ types.OVERLAY_SHOW ]( state, payload ) {
    state.display = true;
  },

  [ types.OVERLAY_HIDE ]( state ) {
    state.display = false;
  },

};

const actions = {

  showOverlay({ commit }, payload ) {
    commit( types.OVERLAY_SHOW );
  },

  hideOverlay({ commit }) {
    commit( types.OVERLAY_HIDE );
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};