export const PLAN_NAME = {
  FREE: "free",
  STARTER: "starter",
  LIGHT: "light",
  STANDARD: "standard",
  PRO: "pro",
  ENTERPRISE: "enterprise",
};

export const PLAN_GRADE = {
  [PLAN_NAME.FREE]: 0,
  [PLAN_NAME.STARTER]: 5,
  [PLAN_NAME.LIGHT]: 1,
  [PLAN_NAME.STANDARD]: 2,
  [PLAN_NAME.PRO]: 3,
  [PLAN_NAME.ENTERPRISE]: 4,
};

/** プランを下位順にソート */
const PLAN_SORT = {
  [PLAN_NAME.FREE]: 0,
  [PLAN_NAME.STARTER]: 1,
  [PLAN_NAME.LIGHT]: 2,
  [PLAN_NAME.STANDARD]: 3,
  [PLAN_NAME.PRO]: 4,
  [PLAN_NAME.ENTERPRISE]: 5,
};

/**
 * @params {string|number} grade_id
 */

export function getPlanGrade( grade_id ){
  // TODO: 理想はAPIでマスターの情報をもらう
  // TODO: 現状のプランより上位のプランだと◯◯を表示するといった実装の場合に、{N}のプランがアップグレードなのか、ダウングレードなのか判別できない
  // → バックエンドがどうなるか次第だが、一旦PLAN_SORTを定義して対応
  const plan_name = {
    0: PLAN_NAME.FREE,
    1: PLAN_NAME.LIGHT,
    2: PLAN_NAME.STANDARD,
    3: PLAN_NAME.PRO,
    4: PLAN_NAME.ENTERPRISE,
    5: PLAN_NAME.STARTER,
  };

  // 文字列化
  const gradeStr = "" + grade_id;

  return plan_name[gradeStr];

}

/**
 * @params {string|number} currentGradeId
 * @params {string} gradeNameToCompare
 */
function isLowerThanPlanGrade( currentGradeId, gradeNameToCompare ) {
  const currentGradeName = getPlanGrade(currentGradeId);
  return PLAN_SORT[currentGradeName] < PLAN_SORT[gradeNameToCompare];
}

/**
 * @params {string|number} currentGradeId
 */
export function isLowerThanStarter( currentGradeId ) {
  return isLowerThanPlanGrade(currentGradeId, PLAN_NAME.STARTER);
}


/**
 * @params {string|number} currentGradeId
 */
export function isLowerThanLight( currentGradeId ) {
  return isLowerThanPlanGrade(currentGradeId, PLAN_NAME.LIGHT);
}

/**
 * @params {string|number} currentGradeId
 */
export function isLowerThanStandard( currentGradeId ) {
  return isLowerThanPlanGrade(currentGradeId, PLAN_NAME.STANDARD);
}

/**
 * @params {string|number} currentGradeId
 */
export function isLowerThanPro( currentGradeId ) {
  return isLowerThanPlanGrade(currentGradeId, PLAN_NAME.PRO);
}

/**
 * @params {string|number} currentGradeId
 */
export function isLowerThanEnterprise( currentGradeId ) {
  return isLowerThanPlanGrade(currentGradeId, PLAN_NAME.ENTERPRISE);
}
