<template>
  <div>
    <v-list
      dense
      color="#EEFAFA"
    >
      <v-list-item-group
        v-model="selectedMainMenu"
        color="primary"
      >
        <v-list-item
          value="deliveries"
          @click="linkTo('/deliveries')"
        >
          <v-list-item-icon>
            <v-icon>mdi-message-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>配信</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          value="lists"
          @click="linkTo('/lists')"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>リスト</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          value="contents"
          @click="linkTo('/contents')"
        >
          <v-list-item-icon>
            <v-icon>mdi-folder-multiple-image</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>コンテンツ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          value="campaigns"
          @click="linkTo('/campaigns')"
        >
          <v-list-item-icon>
            <v-icon>mdi-bullhorn-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>キャンペーン</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          :value="selectedMainMenu === 'analytics'"
          no-action
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-chart-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>分析</v-list-item-title>
            </v-list-item-content>
          </template>        
          <v-list-item
            v-for="item in items"
            :key="item.text"
            :to="item.link"
            @click="linkTo(item.link)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          value="settings"
          @click="linkTo('/settings/users')"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>設定</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data(){
    return {
      items: [
        { text: "友だちレポート", link: "/analytics/friends" },
        { text: "配信", link: "/analytics/deliveries" },
        { text: "リッチメニュー", link: "/analytics/richmenus"},
        { text: "コンテンツ", link: "/analytics/contents" },
        { text: "リスト", link: "/analytics/lists" },
        { text: "URL", link: "/analytics/click_urls" },
        { text: "受信メッセージ", link: "/analytics/received_messages" },
      ],
    };
  },
  computed: {
    selectedMainMenu: {
      get() {
        if (this.$route.path === "/") {
          return "deliveries";
        }
        return this.$route.path.split("/")[1];
      },
      set(newValue) {
        return newValue;
      }
    },
    ...mapState(["account"]),
  },
  methods: {
    linkTo( link ) {
      return location.href = link;
    },
  }
};
</script>