<template>
  <v-menu
    bottom
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn
        text
        v-bind="attrs"
        style="text-transform: none"
        v-on="on"
      >
        <v-icon
          class="mr-1"
          color="grey"
        >
          mdi-account-circle-outline
        </v-icon>
        {{ currentUserName }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        dense
        @click="linkTo('/users/edit')"
      >
        <v-list-item-icon
          class="mr-1"
          color="grey"
        >
          <v-icon>mdi-account-edit-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          ユーザー設定
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        dense
        @click="logout()"
      >
        <v-list-item-icon
          class="mr-1"
          color="grey"
        >
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <!-- Rails側を修正してもらえればもっといいやり方ができそう -->
          <form
            ref="logoutForm"
            action="/logout"
            method="post"
          >
            ログアウト
          </form>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    currentUserName: {
      type: String,
      required: true,
    },
  },
  methods: {
    linkTo( url ){
      location.href = url;
    },
    logout() {
      this.$refs.logoutForm.submit();
    }
  }
};
</script>