<template>
  <div>
    <div :class="`modal ${updateContentsModalStatus}`">
      <div
        class="close_modal"
        @click="setVisibility(false)"
      >
        ×
      </div>
      <div class="modal-background" />
      <div class="modal-content update_contents has-background-white">
        <div class="content">
          <div class="content has-text-centered">
            <div class="block mt-5">
              <h1 class="has-text-left mx-6">
                コンテンツを上書き保存<br>しますか？
              </h1>
            </div>
            <div class="block has-text-left mx-6">
              選択したコンテンツは既に他の配信で利用されています。上書き保存した場合、そのコンテンツを利用した配信はすべて更新されます。
            </div>
            <div
              v-for="content in updateContentsMode"
              :key="content.index"
              class="mb-3"
            >
              <button
                :class="content.class"
                @click="footerButtonClicked(content.mode)"
              >
                {{ content.text }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // components: {
  //   SelectFileModal
  // },
  data() {
    return {
      updateContentsModalStatus: "",
      // 「コンテンツを上書き保存しますか？」モーダルのボタンの内容
      // (どちらも「キャンセル」系の動作ではないので、ここで設定)
      updateContentsMode: [
        {
          index: 0,
          class: "button button_bgcolor_info has-text-white",
          text: "コンテンツを複製する",
          mode: 0,
        },
        {
          index: 1,
          class: "button button_bgcolor_light mb-5",
          text: "コンテンツを上書き保存する",
          mode: 1,
        }
      ],

      UPDATE_CONTENTS_MODE_DUPLICATE: 0,
      UPDATE_CONTENTS_MODE_UPDATE: 1,
    };
  },

  // created(): {},
  // mounted(): {},
  // watch: {},
  // computed: {},

  methods: {
    // モーダルの表示/非表示切り替え
    setVisibility(value) {
      this.updateContentsModalStatus = value ? "is-active" : "";
    },

    footerButtonClicked(mode) {
      // 「コンテンツを上書き保存しますか」モーダルが開いている場合は
      // モーダルを閉じ、「保存しました」モーダルを表示する。
      this.$emit("emitted", mode);
      this.setVisibility(false);
    }
  }
};
</script>

<style scoped>
.button_bgcolor_info {
  background-color: #2F80ED;
}

.button_bgcolor_light {
  background-color: #F6F6F6;
}

.modal .modal-content {
  width: 520px;
  border-radius: 4px;
}

.modal .modal-content .button {
  border-radius: 4px;
}

.modal .update_contents .button {
  width: 300px;
}

/* モーダル右上の「×」ボタン */
.modal .close_modal {
  width: 30px;
  height: 30px;
  top: 0;
  left: 259px;
  text-align: center;
  background-color:rgba(255, 255, 255, 1);
  border-radius: 20px;
  z-index: 2147483647;
  font-weight: bold;
  transform: translateY(15px);
  position: relative;
  box-shadow: -0.25px 0.25px 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

</style>
