import * as types from "@/store/mutation-types";

const state = {
  display: false,
  text: "",
  type: "info",
  permanent: false,
};

const mutations = {

  [ types.SNACKBARS_SHOW ]( state, payload ) {
    state.display = true;
    state.text = payload.text;
    state.type = payload.type || "info";
    state.permanent = payload.permanent || false;
  },

  [ types.SNACKBARS_HIDE ]( state ) {
    state.display = false;
    state.text = "";
    state.type = "info";
    state.permanent = false;
  },

};

const actions = {

  showSnackbars({ commit }, payload ) {
    // 前のスナックバーが表示されている場合に初期化する
    commit( types.SNACKBARS_HIDE );
    commit( types.SNACKBARS_SHOW, payload );
  },

  hideSnackbars({ commit }) {
    commit( types.SNACKBARS_HIDE );
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};