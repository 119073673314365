/* global $ */
export default class Message {
  static fadeOut_() {}

  static alert(messages) {
    this.show(messages, "is-alert");
    this.fadeOut();
  }

  static notice(messages) {
    this.show(messages, "is-notice");
    this.fadeOut();
  }

  static show(messages, cssClassName) {
    clearTimeout(this.fadeOut_);

    if (!$.isArray(messages)) {
      messages = [messages];
    }

    $(".flash-messages-ul li").remove();
    messages.forEach(function(message) {
      $(".flash-messages-ul").append($("<li />").text(message));
    });

    $(".js-messagejs")
      .show()
      .removeClass("is-hidden")
      .removeClass("is-alert")
      .removeClass("is-notice")
      .addClass(cssClassName);
  }

  static hide() {
    clearTimeout(this.fadeOut_);
    $(".js-messagejs").addClass("is-hidden");
  }

  static fadeOut() {
    this.fadeOut_ = setTimeout(() => $(".flash").fadeOut(), 5000);
  }

  static bind() {
    this.fadeOut();

    $(document).off("click", ".js-close-parent__trigger");
    $(document).on("click", ".js-close-parent__trigger", function() {
      $(this)
        .parents(".js-close-parent")
        .addClass("is-hidden")
        .hide();
    });
  }
}
