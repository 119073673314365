<template>
  <v-icon
    :size="fontSize"
    :color="color"
  >
    <slot />
  </v-icon>
</template>

<script>

/**
 * 
 * @fileoverview vuetifyのv-iconを拡張。APIを制限してデザインの揺れを軽減させる目的。
 * 
 * @param { string } size
 * 
 */

const FontSize = {
  XS: "15px",
  S: "16.67px",
  M: "20px",
  L: "26.67px",
};

export default {
  props: {
    size: {
      type: String,
      default: "M",
      validator: function(val){
        return ["XS", "S", "M", "L"].includes(val);
      }
    },
    color: {
      type: String,
      default: undefined,
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fontSize() {
      return FontSize[this.size];
    }
  },
};
</script>
