<!-- 「メディアを選択する」モーダル -->
<template>
  <div>
    <div :class="`modal ${selectMediaModalStatus}`">
      <div
        class="close_modal_select_media"
        @click="setVisibility(false)"
      >
        ×
      </div>
      <div class="modal-background" />
      <div class="modal-content modal_content_select_media has-background-white">
        <div class="content mx-5 mb-5">
          <div class="mt-5">
            <h1 class="has-text-left">
              メディアを選択する
            </h1>
          </div>
          <div>
            <div>
              <div class="file is-info select_file_button_container">
                <label class="file-label">
                  <input
                    ref="modalFileInput"
                    class="file-input"
                    type="file"
                    name="resume"
                    :accept="accepts.join(',')"
                    multiple="true"
                    @change="changeMediaImage"
                  >
                  <span class="file-cta">
                    <span class="file-label has-text-white">
                      新規アップロード
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="errorMessage">
            <span class="has-text-left has-text-danger">{{ errorMessage }}</span>
          </div>
          <div class="block">
            <span
              v-if="selectMode === 0"
              class="has-text-left format_description"
            >利用可能なフォーマット：ファイル形式：JPEG、JPGまたはPNG,最大ファイルサイズ：1MB,px*px</span>
            <span
              v-else-if="selectMode === 1"
              class="has-text-left format_description"
            >利用可能なフォーマット：ファイル形式：MP4,最大ファイルサイズ：10MB,px*px</span>
            <span
              v-else
              class="has-text-left format_description"
            >利用可能なフォーマット：ファイル形式：JPEG、JPGまたはPNGまたはMP4,最大ファイルサイズ：1MB,px*px</span>
          </div>
          <div class="select_media_flex select_media_contents mb-5">
            <div class="select_media_flex_child select_media_contents">
              <div class="search_id mr-2">
                <input
                  v-model="searchId"
                  class="input"
                  type="text"
                  placeholder="ID"
                >
              </div>
              <div class="search_title mr-2">
                <input
                  v-model="searchTitle"
                  class="input"
                  type="text"
                  placeholder="タイトル"
                >
              </div>
              <div
                v-if="selectMode === MODE_BOTH"
                class="mimetype_filter mr-2"
              >
                <div
                  :class="`dropdown ${mimeTypeStatus}`"
                  @click="changeStatus('mimeType')"
                >
                  <div class="dropdown-trigger">
                    <button
                      class="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span>フォーマット</span>
                      <span class="material-icons ml-2">expand_more</span>
                    </button>
                  </div>
                  <div
                    id="dropdown-menu"
                    class="dropdown-menu"
                    role="menu"
                  >
                    <div class="dropdown-content">
                      <a
                        v-if="searchMimeTypeImage"
                        class="dropdown-item mime_selected"
                        @click="changeSearchMimeType('Image')"
                      >画像</a>
                      <a
                        v-else
                        class="dropdown-item mime_unselected"
                        @click="changeSearchMimeType('Image')"
                      >画像</a>
                      <a
                        v-if="searchMimeTypeMovie"
                        class="dropdown-item mime_selected"
                        @click="changeSearchMimeType('Movie')"
                      >動画</a>
                      <a
                        v-else
                        class="dropdown-item mime_unselected"
                        @click="changeSearchMimeType('Movie')"
                      >動画</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="created_at_filter mr-2">
                <div
                  :class="`dropdown ${createdAtStatus}`"
                  @click="changeStatus('createdAt')"
                >
                  <div class="dropdown-trigger">
                    <button
                      v-if="getDispSearchCreatedAt"
                      class="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span>作成日時:{{ searchCreatedAtFrom }}-{{ searchCreatedAtTo }}</span>
                      <div
                        class="clear_created_at"
                        @click="clearCreatedAt(false, true, 'is-active')"
                      >
                        <span class="material-icons close_created_at">close</span>
                      </div>
                      <span class="material-icons ml-2">expand_more</span>
                    </button>
                    <button
                      v-else
                      class="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span>作成日時</span>
                      <span class="material-icons ml-2">expand_more</span>
                    </button>
                  </div>
                  <div
                    id="dropdown-menu"
                    class="dropdown-menu"
                    role="menu"
                  >
                    <div class="dropdown-content">
                      <div
                        class="dropdown-item icon_delete"
                        @click="changeStatus('createdAt')"
                      >
                        <span
                          class="material-icons close_calendar"
                          @click="clearCreatedAt(true, false)"
                        >close</span>
                      </div>
                      <div
                        class="dropdown-item"
                        @click="changeStatus('createdAt')"
                      >
                        <v-date-picker
                          v-model="searchCreatedAt"
                          :model-config="modelConfig"
                          mode="date"
                          is-range
                        />
                      </div>
                      <div
                        class="dropdown-item calendar_buttons"
                        @click="changeStatus('createdAt')"
                      >
                        <button
                          class="button calendar_buttons button_bgcolor_light"
                          @click="clearCreatedAt(false, false, 'is-active')"
                        >
                          クリア
                        </button>
                        <button
                          class="button calendar_buttons button_bgcolor_info has-text-white"
                          @click="setCreatedAt"
                        >
                          検索
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="created_questionnaire_flex_child select_media_contents_pagination">
              <Pagination
                ref="pagination"
                :props-current-page="currentPage"
                :props-rows-count="rowsCount"
                :props-rows-per-page="rowsPerPage"
                @emitted="pageChanged"
              />
            </div>
          </div>
          <div>
            <div class="table-container">
              <table class="table is-hoverable">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>タイトル</th>
                    <th>状態</th>
                    <th>フォーマット</th>
                    <th>プレビュー</th>
                    <th>利用先</th>
                    <th>メモ</th>
                    <th>作成日時</th>
                    <th>配信数</th>
                    <th>開封数</th>
                    <th>開封率</th>
                    <th>クリック数<br>(UU)</th>
                    <th>CTR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="media of getMediaList"
                    :key="`media-${media.id}`"
                    @click="mediaSelected(media.url)"
                  >
                    <td>{{ media.id }}</td>
                    <td>{{ media.title }}</td>
                    <td v-if="media.status === 0">
                      下書き
                    </td>
                    <td v-else-if="media.status === 1">
                      公開
                    </td>
                    <td v-else>
                      公開
                    </td>
                    <td v-if="media.mime_type === 'image'">
                      画像
                    </td>
                    <td v-else-if="media.mime_type === 'video'">
                      動画
                    </td>
                    <td v-else />
                    <td class="figure">
                      <img
                        v-if="media.mine_type==='image'"
                        :src="media.url"
                      >
                      <video
                        v-if="media.mine_type==='video'"
                        :src="media.url"
                      />
                    </td>
                    <td>{{ media.reference }}</td>
                    <td>{{ media.memo }}</td>
                    <td>{{ formatCreatedAt(media.created_at) }}</td>
                    <td class="has-text-right">
                      {{ formatNumber(media.delivery_count) }}
                    </td>
                    <td class="has-text-right">
                      {{ formatNumber(media.opened_count) }}
                    </td>
                    <td class="has-text-right">
                      {{ formatNumber(media.opened_rate, true) }}
                    </td>
                    <td class="has-text-right">
                      {{ formatNumber(media.clicked_count) }}
                    </td>
                    <td class="has-text-right">
                      {{ formatNumber(media.ctr, true) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const MODE_IMAGE_ONLY = 0;
const MODE_MOVIE_ONLY = 1;
const MODE_BOTH = 2;

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

export default {
  props: {
    // 取得するファイル種類の指定。0:画像のみ、1:動画のみ、2:両方
    selectMode: {
      type: Number,
      default: MODE_BOTH,
      required: false,
    }
  },
  data() {
    return {
      // ここで定義しないと、HTML内で使えない
      MODE_IMAGE_ONLY: MODE_IMAGE_ONLY,
      MODE_MOVIE_ONLY: MODE_MOVIE_ONLY,
      MODE_BOTH: MODE_BOTH,

      medias: [],
      errorMessage: "",

      // 検索条件
      searchId: "",
      searchTitle: "",
      searchMimeTypeImage: true,
      searchMimeTypeMovie: true,
      searchCreatedAt: "",
      searchCreatedAtFrom: "",
      searchCreatedAtTo: "",

      // モーダルやドロップダウンのステータス(is-activeかどうか)
      selectMediaModalStatus: "",
      mimeTypeStatus: "",
      createdAtStatus: "",
      archiveStatus: "",

      // 「ファイルを選択してください」モーダルでのデフォルト拡張子
      accepts: [],

      // ページネーションで使う値
      currentPage: 1,  // 現在のページ
      rowsPerPage: 50, // 1ページあたりの行数
      rowsCount: -1,   // 全体の行数(=レコード数)

      modelConfig: {
        type: "string",
        mask: "YYYY/MM/DD",
      },
    };
  },

  computed: {
    // 一覧のフィルタ
    getMediaList() {
      let medias = this.medias.slice(0);

      // 絞込み -> フォーマットでの絞込み
      if (this.selectMode === MODE_IMAGE_ONLY) {
        medias = medias.filter(media => media.mime_type === "image");
      } else if (this.selectMode === MODE_MOVIE_ONLY) {
        medias = medias.filter(media => media.mime_type === "video");
      } else {
        if (this.searchMimeTypeImage && !this.searchMimeTypeMovie) {
          medias = medias.filter(media => media.mime_type === "image");
        } else if (!this.searchMimeTypeImage && this.searchMimeTypeMovie) {
          medias = medias.filter(media => media.mime_type === "video");
        } else if (!this.searchMimeTypeImage && !this.searchMimeTypeMovie) {
          // mime_typeがimageでもmovieでもない、というデータはここでは存在しないので、
          // もしどっちも指定されていない場合、強制的に空配列にしてる
          medias = [];
        }
      }

      if (this.searchId) {
        medias = medias.filter(media => media.id.toString() === this.searchId);
      }

      if (this.searchTitle) {
        medias = medias.filter(media => media.title.indexOf(this.searchTitle) !== -1);
      }

      // 作成日時での指定
      if (this.getDispSearchCreatedAt) {

        // 検索範囲日付は「開始or終了どっちかしかない」というケースはない。
        // 両方あるor両方ないのどちらか
        const from = this.$dayjs(`${this.searchCreatedAtFrom} 00:00:00`);
        const to = this.$dayjs(`${this.searchCreatedAtTo} 23:59:59`);
        medias =
          medias.filter(media => media.created_at !== ""
          && from.isSameOrBefore(this.$dayjs(media.created_at))
          && to.isSameOrAfter(this.$dayjs(media.created_at))
          );
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.rowsCount = medias.length;
      return medias.slice(this.rowsPerPage * (this.currentPage - 1), this.rowsPerPage * this.currentPage);
    },

    // 作成日時の表示条件の判定
    getDispSearchCreatedAt() {
      return (this.searchCreatedAtFrom !== "" && this.searchCreatedAtTo !== "");
    }
  },

  // mounted: {},

  watch: {
    currentPage: function(newVal) {
      this.$refs.pagination.setCuurentPage(newVal);
    },

    rowsCount: function(newVal) {
      this.$refs.pagination.setRowsCount(newVal);
    },
  },

  created: function() {
    this.$dayjs.extend(isSameOrBefore);
    this.$dayjs.extend(isSameOrAfter);
    this.getMedias();
    this.setAccepts();
  },

  methods: {
    // 一覧に表示するメディアを取得する。(実際はDBから)
    // 取得条件はprops.selectModeにより変わる。
    // 「contents.statusが公開終了ではない」は必須、「media_contents.mime_type」は
    // props.selectModeによりimageのみ/movieのみ/両方のいずれか。
    getMedias() {
      const medias = [
        {
          id: 1,
          title: "タイトル1",
          status: 0,  // 0: 下書き, 1: 公開 (公開終了は表示しない)
          data: "",
          mime_type: "image",  // image:画像, movie:動画
          url: "https://tsunagaru-v3-app-mintenant-development-media-contents.s3.ap-northeast-1.amazonaws.com/uploads/medium_content/data/1/suzuki_fujisan.jpg",
          reference: "利用先1",
          memo: "メモ1",
          created_at: "2021-01-01 00:00:00",
          updated_at: "2021-01-01 12:00:00",
          delivery_count: "100",
          opened_count: "10",
          opened_rate: "10.12",
          clicked_count: "1000",
          ctr: "10.345",
        },

        {
          id: 2,
          title: "タイトル2",
          status: 1,
          data: "",
          mime_type: "image",
          url: "https://tsunagaru-v3-app-mintenant-development-media-contents.s3.ap-northeast-1.amazonaws.com/uploads/medium_content/data/1/suzuki_world_leaders.png",
          reference: "利用先2",
          memo: "メモ2",
          created_at: "2021-02-01 00:00:00",
          updated_at: "2021-02-01 12:00:00",
          delivery_count: "200",
          opened_count: "20",
          opened_rate: "20.001",
          clicked_count: "2000",
          ctr: "20.234",
        },
        {
          id: 3,
          title: "タイトル3",
          status: 0,
          data: "",
          mime_type: "image",
          url: "https://tsunagaru-v3-app-mintenant-development-media-contents.s3.ap-northeast-1.amazonaws.com/uploads/medium_content/data/1/suzuki_fujisan.jpg",
          reference: "利用先3",
          memo: "メモ3",
          created_at: "2021-03-01 00:00:00",
          updated_at: "2021-03-01 12:00:00",
          delivery_count: "300",
          opened_count: "30",
          opened_rate: "30.345",
          clicked_count: "3000",
          ctr: "30.4567",
        },
        {
          id: 4,
          title: "タイトル4",
          status: 1,
          data: "",
          mime_type: "image",
          url: "https://tsunagaru-v3-app-mintenant-development-media-contents.s3.ap-northeast-1.amazonaws.com/uploads/medium_content/data/1/suzuki_yoasobi.png",
          reference: "利用先4",
          memo: "メモ4",
          created_at: "2021-04-01 00:00:00",
          updated_at: "2021-04-01 12:00:00",
          delivery_count: "400",
          opened_count: "40",
          opened_rate: "40.45",
          clicked_count: "4000",
          ctr: "40.4567",
        },
        {
          id: 5,
          title: "タイトル5",
          status: 0,
          data: "",
          mime_type: "image",
          url: "https://tsunagaru-v3-app-mintenant-development-media-contents.s3.ap-northeast-1.amazonaws.com/uploads/medium_content/data/1/suzuki_fujisan.jpg",
          reference: "利用先5",
          memo: "メモ5",
          created_at: "2021-05-01 00:00:00",
          updated_at: "2021-05-01 12:00:00",
          delivery_count: "500",
          opened_count: "50",
          opened_rate: "50.12345",
          clicked_count: "5000",
          ctr: "50.567899",
        },
        {
          id: 6,
          title: "タイトル6",
          status: 1,
          data: "",
          mime_type: "video",
          url: "https://tsunagaru-v3-app-mintenant-development-media-contents.s3.ap-northeast-1.amazonaws.com/uploads/medium_content/data/1/suzuki_questionnaire.mp4",
          reference: "利用先6",
          memo: "メモ6",
          created_at: "2021-06-01 00:00:00",
          updated_at: "2021-06-01 12:00:00",
          delivery_count: "600",
          opened_count: "60",
          opened_rate: "60.113340",
          clicked_count: "6000",
          ctr: "60.123456",
        }
      ];

      this.medias.splice(0, 0, ...medias);
    },

    // 数値のフォーマット整形。
    formatNumber(num, isRate = false) {
      const numString = num ? Number(num).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 5}) : "";
      if (isRate) {
        return `${numString}%`;
      } else {
        return numString;
      }
    },

    // 作成日時のフォーマット整形。
    formatCreatedAt(createdAt) {
      if (!createdAt) return;
      return this.$dayjs(createdAt).format("YYYY/MM/DD HH:mm");
    },

    // 「props.selectMode」の値に応じて「新規アップロード」のデフォルトのファイルを設定する
    setAccepts() {
      switch (this.selectMode) {
      case MODE_IMAGE_ONLY:
        this.accepts = [".jpg", ".jpeg", ".png"];
        break;
      case MODE_MOVIE_ONLY:
        this.accepts = [".mp4"];
        break;
      default:
        this.accepts = [".jpg", ".jpeg", ".png", ".mp4"];
        break;
      }
    },

    // モーダルの表示/非表示切り替え
    setVisibility(value) {
      this.selectMediaModalStatus = value ? "is-active" : "";
    },

    // 「アンケート内容」の「ヘッダー画像」でファイル選択した際の処理
    changeMediaImage() {
      const FILE_SIZE_LIMIT_MAX = 1 * 1024 * 1024 * 10;
      const MAX_MEDIA_COUNT = 10;
      let errorMessage = "";

      if (this.$refs.modalFileInput.files.length === 0) {
        return;
      } else if (MAX_MEDIA_COUNT < this.$refs.modalFileInput.files.length) {
        this.errorMessage = `一度にアップロード出来るファイルは最大${MAX_MEDIA_COUNT}個です。`;
        return;
      }

      // フロント側で出来るバリデーションチェック
      for (let i = 0; i < this.$refs.modalFileInput.files.length; i++) {
        const fileSize = this.$refs.modalFileInput.files[i].size;
        const fileExt = this.$refs.modalFileInput.files[i].name.lastIndexOf(".") !== -1
          ? this.$refs.modalFileInput.files[i].name.slice(this.$refs.modalFileInput.files[i].name.lastIndexOf("."))
          : "";

        if (FILE_SIZE_LIMIT_MAX < fileSize || this.accepts.findIndex(ext => ext === fileExt) === -1) {
          errorMessage = "アップロードしたファイルは利用できないファイル形式またはファイルサイズです。";
          break;
        }

        if (fileExt === ".mp4" && 1 < this.$refs.modalFileInput.files.length) {
          errorMessage = "動画ファイルは他のファイルとの同時アップロードはできません。";
          break;
        }
      }

      this.errorMessage = errorMessage;

      // TODO: ファイルのアップロード処理(サーバー側でのバリデーション＆登録処理)
      // FYI: 下記、ファイルを読み込むコード
      // this.selectFileIamge = this.$refs.modalFileInput.files[0];
      // this.selectFileImageName = this.$refs.modalFileInput.files[0].name;
      // const reader = new FileReader();
      // reader.readAsDataURL(this.$refs.modalFileInput.files[0]);

      // reader.onload = () => {
      //   this.selectFileImageBase64 = reader.result;
      // };

      return;
    },

    // メディアが選択(=クリック)された際の処理
    mediaSelected(url) {
      this.$emit("emitted", url);
      this.setVisibility(false);
      this.searchId = "";
      this.searchText = "";
    },

    // 各種のStatus(表示/非表示)を切り替える
    changeStatus(variable) {
      // console.log(variable);
      this[`${variable}Status`] = this[`${variable}Status`] === "" ? "is-active" : "";
      // console.log(this.archiveStatus);
    },

    // ページネーションリンクがクリックされた場合の処理
    pageChanged(page) {
      this.currentPage = page;
      this.$refs.pagination.setCuurentPage(page);
    },

    // 「絞込み」->「フォーマット」の値を切り替える
    changeSearchMimeType(variable) {
      this[`searchMimeType${variable}`] = !this[`searchMimeType${variable}`];
    },

    // 「アンケートを選択する」モーダルの「作成日」カレンダーの「クリア」、または
    // 最上段の「×」をクリックした際の処理
    clearCreatedAt(isClose, isClearAll, status = "") {
      if (isClose) {
        this.createdAtStatus = "";
        return;
      }

      // 選択をクリアし「×」ボタンの場合はカレンダーを閉じる
      this.searchCreatedAt = "";

      if (isClearAll) {
        this.searchCreatedAtFrom = "";
        this.searchCreatedAtTo = "";
      }

      // 表示状態の設定。
      // ただし作成日時が選択されている際に作成日時横の「×」をクリックした場合は、
      // statusの値と逆になる。(dropdown-triggerの内部をクリックしているので)
      this.createdAtStatus = status;
    },

    // 「アンケートを選択する」モーダルの「作成日」カレンダーの「検索」をクリックした際の処理
    setCreatedAt() {
      if (this.searchCreatedAt.start && this.searchCreatedAt.end) {
        this.searchCreatedAtFrom = this.searchCreatedAt.start;
        this.searchCreatedAtTo = this.searchCreatedAt.end;
      } else {
        this.searchCreatedAtFrom = "";
        this.searchCreatedAtTo = "";
      }

      this.createdAtStatus = "";
    },
  },
};
</script>

<style scoped>
.format_description {
  color: #828282;
}

.button_bgcolor_info {
  background-color: #2F80ED;
}

.button_bgcolor_light {
  background-color: #F6F6F6;
}

/* 「メディアを選択する」モーダル */
.modal .modal_content_select_media {
  width: 1058px;
  height: 830px;
  border-radius: 8px;
}

/* 「メディアを選択する」モーダル右上の「×」ボタン */
.modal .close_modal_select_media {
  width: 30px;
  height: 30px;
  top: 0px;
  left: 525px;
  text-align: center;
  background-color:rgba(255, 255, 255, 1);
  border-radius: 20px;
  z-index: 2147483647;
  font-weight: bold;
  transform: translateY(15px);
  position: relative;
  box-shadow: -0.25px 0.25px 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

/* 「実績」モーダル右上の「×」ボタン */
.modal .close_modal_archives {
  width: 30px;
  height: 30px;
  top: 0px;
  left: 250px;
  text-align: center;
  background-color:rgba(255, 255, 255, 1);
  border-radius: 20px;
  z-index: 2147483647;
  font-weight: bold;
  transform: translateY(15px);
  position: relative;
  box-shadow: -0.25px 0.25px 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.modal .select_media_flex,
.modal .select_media_flex_child {
  display: flex;
  box-sizing: border-box;
}

.select_media_flex_child > .mimetype_filter .mime_selected,
.select_media_flex_child > .mimetype_filter .mime_unselected {
  padding-left: 2rem;
  cursor: pointer
}

.select_media_flex_child > .mimetype_filter .mime_selected::before {
  position: absolute;
  content: "check";
  font-family: "Material Icons";
  font-size: 20px;
  top: 50%;
  left: 5px;
  margin-top: -15px;
  width: 20px;
  height: 20px;
}

.select_media_flex_child > .mimetype_filter .mime_unselected::before {
  position: absolute;
  content: "";
  font-family: "Material Icons";
  font-size: 20px;
  top: 50%;
  left: 5px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
}

.select_media_flex > .select_media_contents {
  width: 70%;
}

.select_media_flex .select_media_contents_pagination {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.modal .select_file_button_container, .modal .select_media_contents .dropdown-content > div.icon_delete {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
}

.select_media_contents .search_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  padding-top: 0;
}

/* 「絞込み」ドロップダウン(詳細は後で) */
.select_media_contents .search_filter > .dropdown > .dropdown-trigger > button {
  border: none;
  border-radius: 10px;
}

/* 以下「ID」「タイトル」検索ボックス用設定(大きさ、虫眼鏡表示設定など) */
.select_media_contents .search_title, .select_media_contents .search_id {
  display: inline-block; /* なくても大丈夫だけど、念の為 */
  position: relative;    /* 基準値とする */
}

.select_media_contents .search_title::before, .select_media_contents .search_id::before {
  content: "search";           /* 疑似要素に必須 */
  font-family: "Material Icons";
  font-size: 20px;
  width: 24px;           /* アイコンの横幅 */
  height: 24px;          /* アイコンの高さ */
  display: inline-block; /* 高さを持たせるためにインラインブロック要素にする */
  position: absolute;    /* 相対位置に指定 */
  top: 2px;              /* アイコンの位置。微調整してね */
  left: 2px;             /* アイコンの位置。微調整してね */
  z-index: 1;
  margin: 5px 10px 5px 10px;
}

.select_media_contents .search_title > input, .select_media_contents .search_id > input {
  padding: 3px 0 3px 2.5rem; /* アイコンを設置するため左の余白を多めに指定*/
  border-radius: 10px;
  background-color: #F6F6F6;
}

/* 以下「ID」「タイトル」検索ボックス用設定はこの項目まで */
.select_media_contents .search_id > input {
  width: 6rem;
}

.modal .modal-content .button {
  border-radius: 4px;
}

.modal-content table {
  white-space: nowrap;
}

.modal-content table > tbody > tr {
  cursor: pointer;
}

.modal .modal_file {
  width: 1065px;
  border-radius: 8px;
}

/* モーダル右上の「×」ボタン */
.modal .close_modal_file {
  width: 30px;
  height: 30px;
  top: 0;
  left: 532px;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  z-index: 2147483647;
  font-weight: bold;
  transform: translateY(15px);
  position: relative;
  box-shadow: -0.25px 0.25px 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.modal table .figure {
  max-width: 256px;
  max-height: 256px;
}

.modal table .figure img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.created_at_filter .clear_created_at {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
}

.material-icons.close_created_at, .material-icons.close_calendar {
  font-size: 20px;
  vertical-align: middle;
  margin-top: -5px;
  cursor: pointer;
}

.calendar_buttons {
	display: inline-block;
	width: 100%;
  text-align: center;
}

.calendar_buttons > .button {
  width: 30%;
  border-radius: 10px;
}

</style>
