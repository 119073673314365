import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VMain,
        { staticClass: "grey lighten-4 mb-6" },
        [
          _c(
            VSheet,
            {
              staticClass: "d-flex flex-column justify-space-between",
              staticStyle: { "min-height": "96vh" },
              attrs: { color: "transparent" }
            },
            [_vm._t("default"), _vm._v(" "), _c("LayoutFooter")],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VDialog,
        {
          attrs: { "max-width": _vm.dialog.width || "650px" },
          model: {
            value: _vm.dialog.display,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "display", $$v)
            },
            expression: "dialog.display"
          }
        },
        [
          _c(
            "div",
            { staticClass: "white pa-4" },
            [
              _c(
                _vm.dialog.componentName,
                _vm._g(
                  _vm._b(
                    { tag: "component" },
                    "component",
                    _vm.dialog.props,
                    false
                  ),
                  _vm.dialog.on
                )
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }