<template>
  <div>
    <v-main class="grey lighten-4 mb-6">
      <v-sheet
        class="d-flex flex-column justify-space-between"
        style="min-height: 96vh"
        color="transparent"
      >
        <slot />
        <LayoutFooter />
      </v-sheet>
    </v-main>
    <!-- ダイアログ表示用 -->
    <v-dialog
      v-model="dialog.display"
      :max-width="dialog.width || '650px'"
    >
      <div class="white pa-4">
        <component
          :is="dialog.componentName"
          v-bind="dialog.props"
          v-on="dialog.on"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LayoutFooter from "@/shared/components/layout/layoutFooter";
import dynamicModals from "@/utils/dynamicModals";

export default {
  components: {
    LayoutFooter,
    ...dynamicModals,
  },
  computed: {
    // ダイアログの表示・非表示、コンポーネント名・プロパティを取得
    ...mapState(["dialog"])
  },
};
</script>