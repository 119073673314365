import { isNumber, deepCopy } from "@/utils/helpers";
import { toCamelCase } from "../../../utils/helpers";
import { QUESTIONNAIRES_TYPE } from "../components/questionnairesConstants";

// 親プロパティとorder値を初期化
export const cleanupBranchParents = questions => {
  for ( let qi = 0;  qi < questions.length; ++qi ) {
    const q = questions[qi];
    // branchParentsを初期化
    if ( q.branchParents ) q.branchParents = [];
  }
  return questions;
};

/**
 * 
 * 選択肢の情報をもとに条件分岐元の情報を追加・更新
 * 
 */
export const setBranchParents = questions => {
  // 
  cleanupBranchParents( questions );
  for ( let qi = 0;  qi < questions.length; ++qi ) {
    const q = questions[qi];
    // console.log("setBranchParents: ", q);
    if ( !q.branchParents ) q.branchParents = [];

    // 設問の選択肢をループして条件分岐の依存関係を整理
    for ( let qci = 0; qci < q.questionnaire_choices.length; qci++ ) {
      const qc = q.questionnaire_choices[qci];

      // 設問の選択肢内で条件分岐先として設定しているか選択肢内のbranchesプロパティで確認
      for ( let bi = 0; bi < qc.branches.length; bi++ ) {
        const branch = qc.branches[bi];
        // console.log( branch );
        if ( isNumber( branch.index )) {
          // 重複がなければ追加
          let index = null;
          questions.map(( question, question_index ) => {
            if ( question.branch.uid === branch.uid ) {
              index = question_index;
            }
          });
          // console.log( index);

          if ( !questions[index].branchParents?.some( bq => bq?.index === q.branch.index ) ) { 
            questions[index].branchParents.push({
              index: q.branch.index,
              uid: q.branch.uid,
            }); 
          }
        }
      }
    }
  }
  return questions;
};

/**
 * 
 * questionsのindex, order値を正しい順序に整理
 * 
 */
export const reorderEnableQuestions = questions => {
  const questions_c = deepCopy( questions );
  // 1.question.branchのindex, orderを更新
  const indexCollection = {}; // 設問のindexを管理
  let questionIndex = 0;
  for ( let i = 0; i < questions_c.length; i++ ) {
    if ( questions_c[i].kind === "question" && questions_c[i].is_enable ) {
      questions_c[i].branch.index = questionIndex;
      indexCollection[ questions_c[i].branch.uid ] = {};
      indexCollection[ questions_c[i].branch.uid ].index = questionIndex;
      questionIndex++;
    }
    else {
      questions_c[i].branch.index = null;
    }
    questions_c[i].order = i;
  }

  // 2.条件分岐の情報を更新
  for ( let qi = 0; qi < questions_c.length; qi++ ) {
    // 条件分岐元のindex更新 ( branchParents )
    if ( questions_c[qi].branchParents.length > 0 ) {
      for( let qbi = 0; qbi < questions_c[qi].branchParents.length; qbi++ ){

        // 親のindexを取得 ( タイトルや画像を含まない設問のみのindex値 )
        const parentUid = questions_c[qi].branchParents[qbi].uid;

        // indexCollectionはkind='question'の設問のみ。uidをキーにしたオブジェクトに入っている。
        // そちらからindex値を取得（分岐が関係するのは、kind='question'のみの為）
        const parentIndex = indexCollection[parentUid]?.index;

        if ( parentIndex === undefined ) {
          questions_c[qi].branchParents.splice( qbi, 1 );
        }
        else {
        // 親の設問が自分の設問よりindex値が大きければ（親が先にきてれば）親情報を付与追加
          if ( questions_c[qi].branch.index > parentIndex ) {
            questions_c[qi].branchParents[qbi].index = parentIndex;
          }
          else {
            questions_c[qi].branchParents.splice( qbi, 1 );
          }
        }
      }
    }
    // 設問の選択肢で条件分岐設定がある場合に条件分岐先のindexを更新
    if ( questions_c[qi].questionnaire_choices.length > 0 ) {
      for ( let qc = 0; qc < questions_c[qi].questionnaire_choices.length; qc++ ) {
        if ( questions_c[qi].questionnaire_choices[qc].branches.length > 0 ) {

          for ( let qcbi = 0; qcbi < questions_c[qi].questionnaire_choices[qc].branches.length; qcbi++ ){

            // 分岐先設問のuid
            const selectedUid = questions_c[qi].questionnaire_choices[qc].branches[qcbi].uid;

            if ( !indexCollection[selectedUid] ) {
              // 分岐先の設問がアーカイブに移動すると分岐先の情報がないので選択肢から削除
              alert("分岐設問が変更されたため、分岐設定を解除しました");
              questions_c[qi].questionnaire_choices[qc].branches.splice( qcbi, 1 );
            }
            else {
              const selectedNewIndex = indexCollection[selectedUid].index;
              // 更新前のindex情報 ( IndexCollection )と比較して、インデックスが小さい場合は削除
              if ( selectedNewIndex < questions_c[qi].branch.index ) {
                alert("分岐設問が移動されたため、分岐設定を解除しました");
                questions_c[qi].questionnaire_choices[qc].branches.splice( qcbi, 1 );
              }
              else {
                questions_c[qi].questionnaire_choices[qc].branches[qcbi].index = selectedNewIndex;
              }
            }
          }
        }
      }
    }
  }
  // console.timeEnd("reorderEnableQuestions");
  // debugQuestionsIndex( questions_c );
  return questions_c;
};

const debugQuestionsIndex = questions => {
  // デバッグ用
  questions.map(( q, index ) => {
    console.log("  ");
    console.log( "=============", index + 1, "つめのセクション =============" );
    console.log( "タイトル:", q.question );
    console.log("設問index:", q.branch.index);
    console.log( "order:", q.order );
    console.log( "種別:", q.kind );
    console.log(" 設問uid:", q.branch.uid );
    console.log("  ");
    q.branchParents.map((qb, qbi) =>{
      console.log("分岐元", qbi ," index: ", qb.index, " uid:", qb.uid );
    });
    console.log("選択肢の数:", q.questionnaire_choices.length );
    q.questionnaire_choices.map(( qc, index ) => {
      console.log("  選択肢", index + 1 , ": ", qc.text);
      qc.branches.map(( qcb, qcbi ) => {
        console.log("    選択肢", index+1, "の", qcbi+1, "つめの分岐先設問順: ", qcb.index, ", id:", qcb.uid );
      });
    });
    console.log("  ");
  });
};

export const formartQuestion = question => {
  const ignoreProps = ["updated_at", "created_at", "campaign_id", "branchParents"];
  const q = deepCopy( question );
  const formatedQuestions = Object.keys( q ).reduce(( acc, key ) => {
    if ( ignoreProps.indexOf( key ) > 0 ) return acc;
    let newKey = toCamelCase( key ); 
    let val = q[key];

    if ( key === "questionnaire_choices" ) {
      val = q[key].map( qc => {
        const choices = Object.keys( qc ).reduce(( acc_c, key_c ) => {
          if ( ignoreProps.indexOf( key_c ) > 0 ) return acc_c;
          const newKey_c = toCamelCase( key_c ); 
          acc_c[ newKey_c ] = qc[key_c];
          return acc_c;
        }, {});
        return choices; 
      });
    }
    if ( key === "question" ) newKey = "title";
    if ( key === "kind")  val = QUESTIONNAIRES_TYPE[ (q[key]).toUpperCase() ];
    acc[ newKey ] = val;
    return acc;
  }, {});
  // console.log( "formart: ", formatedQuestions );

  // TODO: タイトルや画像の場合はbranch属性があるとサーバー側でエラーになるので強引に処理
  if ( formatedQuestions.kind !== 2 ) delete formatedQuestions.branch;
  return formatedQuestions;
};