import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VMenu,
    {
      attrs: { bottom: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                VBtn,
                _vm._g(
                  _vm._b(
                    {
                      staticStyle: { "text-transform": "none" },
                      attrs: { text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    VIcon,
                    { staticClass: "mr-1", attrs: { color: "grey" } },
                    [_vm._v("\n        mdi-account-circle-outline\n      ")]
                  ),
                  _vm._v("\n      " + _vm._s(_vm.currentUserName) + "\n    ")
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        VList,
        [
          _c(
            VListItem,
            {
              attrs: { dense: "" },
              on: {
                click: function($event) {
                  return _vm.linkTo("/users/edit")
                }
              }
            },
            [
              _c(
                VListItemIcon,
                { staticClass: "mr-1", attrs: { color: "grey" } },
                [_c(VIcon, [_vm._v("mdi-account-edit-outline")])],
                1
              ),
              _vm._v(" "),
              _c(VListItemTitle, [
                _vm._v("\n        ユーザー設定\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VListItem,
            {
              attrs: { dense: "" },
              on: {
                click: function($event) {
                  return _vm.logout()
                }
              }
            },
            [
              _c(
                VListItemIcon,
                { staticClass: "mr-1", attrs: { color: "grey" } },
                [_c(VIcon, [_vm._v("mdi-logout")])],
                1
              ),
              _vm._v(" "),
              _c(VListItemTitle, [
                _c(
                  "form",
                  {
                    ref: "logoutForm",
                    attrs: { action: "/logout", method: "post" }
                  },
                  [_vm._v("\n          ログアウト\n        ")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }