const VueAxiosPlugin = {};
export default VueAxiosPlugin.install = function(Vue, { axios }) {
  const axiosWithoutHeader = axios.create({});
  const csrf_token = document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content");

  const mutationHeaders = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": csrf_token
  };
  axios.defaults.headers.post = mutationHeaders;
  axios.defaults.headers.put = mutationHeaders;
  axios.defaults.headers.patch = mutationHeaders;
  axios.defaults.headers.delete = mutationHeaders;
  axios.defaults.headers.get = {
    "X-Requested-With": "XMLHttpRequest",
  };

  axios.interceptors.response.use( response => {
    return response;
  }, function (error) {
    /** apiのresponse形式が揃うまでerrorをそのまま返す */
    return Promise.reject( error );
  });

  Vue.axios = axios;
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get () {
        return axios;
      }
    },
    $http: {
      get () {
        return axiosWithoutHeader;
      }
    }
  });
};
