<template>
  <v-snackbar
    v-model="displaySnackbar"
    color="white"
    text
    top
    center
    :timeout="permanent || type === 'error' ? -1 : 5000"
    elevation="6"
    class="mb-6 ml-6"
  >
    <div
      class="d-inline-flex align-center"
      style="gap: 12px;"
    >
      <span :class="iconAreaClass">
        <v-icon
          :color="type"
          size="20"
        >
          {{ icon }}
        </v-icon>
      </span>
      <span
        class="text-subtitle-2 black--text"
        v-html="text"
      />
    </div>
    <template #action="{ attrs }">
      <v-btn
        text
        icon
        color="grey-600"
        v-bind="attrs"
        @click="handleClose()"
      >
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
const ICONS = {
  info: "mdi-information",
  success: "mdi-check-circle",
  warning: "mdi-alert",
  error: "mdi-information",
};

/**
 * 
 * @fileoverview vuetifyのスナックバーを拡張。
 * 
 * @param { string } text - スナックバーの表示制御に利用
 * @param { string } type - 通知のタイプ。主に色の指定に利用
 * @param { boolean } display - スナックバーの表示制御に利用
 * @param { function } handleClose - closeボタンを押した際の挙動を指定
 * 
 */
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "info",
      validator: function(val) {
        return ["primary", "secondary", "error", "info", "success", "warning"].includes(val);
      },
    },
    display: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
      default: null,
    },
    permanent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displaySnackbar: {
      get() {
        return this.display;
      },
      set(newValue) {
        if (!newValue) {
          this.handleClose();
        }
      }
    },
    iconAreaClass() {
      return `icon-area ${this.type}-transparent-16`;
    },
    icon() {
      return ICONS[this.type] || "mdi-check-circle";
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-area {
  display: inline-flex;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}
</style>
