<template>
  <div class="column is-4 text-preview">
    <div class="space-between">
      <div class="flex-start align-center">
        <i class="material-icons i-navigate">navigate_before</i>
        <i class="material-icons i-local-police md-light">local_police</i>TSUNAGARU V3
      </div>
      <div class="space-around align-center">
        <i class="material-icons i-right i-access_time">access_time</i>
        <i class="material-icons i-right i-assignment">assignment</i>
        <i class="material-icons i-right i-dehaze">dehaze</i>
      </div>
    </div>
    <div class="align-center">
      <i class="material-icons i-people">people_outline</i>
      <img
        :src="preview_img"
        class="preview-img"
      >
      <div class="preview-textbox">
        <p class="preview-text">
          {{ location_name }} <br> {{ address }}
        </p>
        <div class="preview-textborder" />
        <div class="preview-location">
          Location
        </div>
        <div class="preview-arrow">
          &lt;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preview_img: String,
    location_name: String,
    address: String
  }
};
</script>

<style scoped>
  .flex-center {
    display: flex;
    justify-content: center;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .align-start {
    display: flex;
    align-items: flex-start;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .space-around {
    display: flex;
    justify-content: space-around;
  }

  .v-application p {
    margin-bottom: 0;
  }

  .text-preview {
    width: 375px;
    height: 667px;
    background: #89a4d9;
    box-shadow: 0 4px 100px rgba(0, 0, 0, 0.2);
    padding-right: 2%;
    margin-top: 8%;
  }

  .i-people {
    display: flex;
    width: 32px;
    height: 32px;
    background: #223aa3;
    color: #f6faf7;
    border-radius: 50%;
    margin-top: 10%;
    padding: 1%;
  }

  .i-navigate {
    display: flex;
    font-size: 35px;
  }

  .i-local-police {
    display: flex;
    color: #6dfa80;
  }

  .i-right {
    display: flex;
    margin-right: 25%;
  }

  .preview-img {
    position: absolute;
    width: 205px;
    height: 121px;
    left: 50px;
    top: 75px;
    border-radius: 15px 15px 0px 0px;
  }

  .preview-textbox {
    position: absolute;
    width: 205px;
    height: 74px;
    left: 50px;
    top: 195px;
    background: #FFFFFF;
    border-radius: 0px 0px 15px 15px;
  }

  .preview-text {
    position: absolute;
    width: 182px;
    height: 28px;
    left: 18px;
    top: 10px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Hiragino Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    color: #000000;
  }

  .preview-textborder {
    position: absolute;
    width: 205px;
    left: 0px;
    top: 50px;
    border: 1px solid #E8E5E5;
  }

  .preview-location {
    position: absolute;
    width: 39px;
    height: 11px;
    left: 20px;
    top: 56px;
    font-family: Hiragino Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 8px;
    line-height: 140%;
    color: #828282;
  }

  .preview-arrow {
    position: absolute;
    left: 175px;
    top: 50px;
    color: #828282;
    transform: rotate(-180deg);
  }
</style>
