<template>
  <div>
    <!-- 「編集内容を下書き保存しますか」Modal -->
    <div :class="`modal ${draftModalStatus}`">
      <div
        class="close_modal_draft"
        @click="setVisibility(false)"
      >
        ×
      </div>
      <div class="modal-background" />
      <div class="modal-content modal_draft has-background-white">
        <div class="content">
          <div class="content has-text-centered">
            <div class="block mt-5">
              <h1 class="has-text-centered">
                編集内容を下書き保存しますか？
              </h1>
            </div>
            <div class="block save_items has-text-left">
              "保存せずに戻る"ボタンを押すとこれまで編集した内容が削除されます。作成途中の場合は"保存して戻る"ボタンを押してコンテンツを下書き保存してください。
            </div>
            <div class="mb-5">
              <div class="buttons footer_item">
                <button
                  class="button button_bgcolor_light"
                  @click="footerButtonClicked(false)"
                >
                  保存せずに戻る
                </button>
                <button
                  class="button button_bgcolor_info has-text-white"
                  @click="footerButtonClicked(true)"
                >
                  保存して戻る
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      draftModalStatus: "",
    };
  },

  // created: {},
  // mounted: {},
  // watch: {},
  // computed: {},

  methods: {
    // モーダルの表示/非表示切り替え
    setVisibility(value) {
      this.draftModalStatus = value ? "is-active" : "";
    },

    footerButtonClicked(isSave) {
      // 「コンテンツを上書き保存しますか」モーダルが開いている場合は
      // モーダルを閉じ、「保存しました」モーダルを表示する。
      this.$emit("emitted", isSave);
      this.setVisibility(false);
    },
  }
};
</script>

<style scoped>
.button_bgcolor_info {
  background-color: #2F80ED;
}

.button_bgcolor_light {
  background-color: #F6F6F6;
}

.footer_item {
	display: inline-block;
	width: 100%;
  text-align: center;
}

.modal .modal-content {
  width: 456px;
  border-radius: 4px;
}

.modal .modal-content .button {
  border-radius: 4px;
}

.modal .modal_template, .modal .modal_draft, .modal .modal_file {
  width: 1065px;
  border-radius: 8px;
}

.modal .close_modal_template, .modal .close_modal_draft, .modal .close_modal_file {
  width: 30px;
  height: 30px;
  top: 0;
  left: 532px;
  text-align: center;
  background-color:rgba(255, 255, 255, 1);
  border-radius: 20px;
  z-index: 2147483647;
  font-weight: bold;
  transform: translateY(15px);
  position: relative;
  box-shadow: -0.25px 0.25px 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.modal_draft .button {
  width: 250px;
}

.save_items {
  width: 70%;
  margin: 0 auto;
}

</style>
