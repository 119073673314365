// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

/* eslint-disable no-unused-vars, no-undef */

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import Vue from "vue";
import VueRouter from "vue-router";
import router from "../routes";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import draggable from "vuedraggable";
import VModal from "vue-js-modal";
import dayjs from "dayjs";
import "dayjs/locale/ja";

import App from "../src/app";
import SelectMediaModal from "../src/shared/components/modal/selectMediaModal";
import IncompleteInputModal from "../src/shared/components/modal/incompleteInputModal";
import SavedModal from "../src/shared/components/modal/savedModal";
import PointCodeSavedModal from "../src/shared/components/modal/pointCodesavedModal";
import UpdateContentsModal from "../src/shared/components/modal/updateContentsModal";
import DraftModal from "../src/shared/components/modal/draftModal";
import Pagination from "../src/shared/components/pagination";
import FlashMessage from "../src/shared/components/modal/FlashMessage";
import LocationMap from "../src/shared/components/preview/locationMap";
import ReceiveLogTable from "../src/shared/components/receiveLogTable";

require("jquery");
import Message from "./message";
Message.bind();

// imagesをERBから読み込めるように設定
// imagePathのほうはなくても動くが
// 公式ドキュメントに必要と記載があったので追加
require.context("../images/", true);
const imagePath = (name) => images(name, true);

// axios デフォルトcsrf-token設定
import VueAxiosPlugin from "./plugins/vue-axios";
Vue.use(VueAxiosPlugin, { axios: axios });

// vuetify theme設定
import light from "./plugins/vuetify-theme";

Rails.start();
ActiveStorage.start();

import vuetify from "./plugins/vuetify";
Vue.use(VueRouter);
Vue.use(VModal);
import store from "@/store/";

dayjs.locale("ja");
Vue.prototype.$dayjs = dayjs;

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

import { ValidationProvider, ValidationObserver } from "./plugins/vee-validate";

// コンポーネント設定
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("Draggable", draggable);
Vue.component("SelectMediaModal", SelectMediaModal);
Vue.component("IncompleteInputModal", IncompleteInputModal);
Vue.component("SavedModal", SavedModal);
Vue.component("PointCodeSavedModal", PointCodeSavedModal);
Vue.component("UpdateContentsModal", UpdateContentsModal);
Vue.component("DraftModal", DraftModal);
Vue.component("FlashMessage", FlashMessage);
Vue.component("Pagination", Pagination);
Vue.component("LocationMap", LocationMap);
Vue.component("ReceiveLogTable", ReceiveLogTable);

library.add(fas);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

Vue.config.productionTip = false;

document.addEventListener("DOMContentLoaded", () => {
  const appDataset = $("#app").data() || {};
  const appPrevDataset = $("#app").prev().data() || {};
  new Vue({
    el: "#app",
    components: {
      ValidationProvider,
      ValidationObserver,
      draggable,
      SelectMediaModal,
      IncompleteInputModal,
      SavedModal,
      UpdateContentsModal,
      DraftModal,
      FlashMessage,
      Pagination,
      LocationMap,
      ReceiveLogTable,
    },
    vuetify,
    router,
    store,
    render: (h) => h(App,
      {
        props: {
          appProps: appPrevDataset,
          messages: { texts: appDataset.messages, type: appDataset.messageType } || {}
        }
      }
    ),
  });
});
