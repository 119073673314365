<!-- ページネーションを表示する共通コンポーネント -->
<template>
  <div>
    <div class="pagination">
      <v-icon
        class="previous"
        color="#333333"
        :disabled="!getPriviousLinkStatus"
        @click="pageSelected(1)"
      >
        keyboard_double_arrow_left
      </v-icon>
      <v-icon
        class="previous"
        color="#333333"
        :disabled="!getPriviousLinkStatus"
        @click="pageSelected(currentPage - 1)"
      >
        keyboard_arrow_left
      </v-icon>
      <div
        v-for="page in getDispPages"
        :key="page"
      >
        <span
          v-if="currentPage !== page"
          class="page has-text-weight-bold"
          @click="pageSelected(page)"
        >{{ page }}</span>
        <div
          v-else
          class="page-disabled"
        >
          {{ page }}
        </div>
      </div>
      <v-icon
        class="next"
        color="#333333"
        :disabled="!getNextLinkStatus"
        @click="pageSelected(currentPage + 1)"
      >
        keyboard_arrow_right
      </v-icon>
      <v-icon
        class="next"
        color="#333333"
        :disabled="!getNextLinkStatus"
        @click="pageSelected(totalPageCount)"
      >
        keyboard_double_arrow_right
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propsRowsCount: {
      // 一覧(テーブルなど)に表示する全件数
      type: Number,
      required: true,
    },
    propsCurrentPage: {
      // 現在表示しているページ
      type: Number,
      required: true,
    },
    propsDispPageCount: {
      // ページネーションに表示するページ数(「...」などにしないページ数)
      type: Number,
      required: false,
      default: 5,
    },
    propsRowsPerPage: {
      // 1ページに表示するデータ(レコード)数
      type: Number,
      required: false,
      default: 50
    }
  },
  data() {
    return {
      currentPage: this.propsCurrentPage,
      dispPageCount: this.propsDispPageCount,
      rowsPerPage: this.propsRowsPerPage,
    };
  },
  // mounted(): {},
  computed: {
    rowsCount() {
      return this.propsRowsCount;
    },
    totalPageCount() {
      if (this.rowsCount <= this.rowsPerPage) return 1;
      return Math.ceil(this.rowsCount / this.rowsPerPage);
    },
    // 先頭ページまたは全ページのリンクを有効にするかの判定
    getPriviousLinkStatus() {
      return (1 < this.totalPageCount && 1 < this.currentPage);
    },

    // 次ページまたは最終ページのリンクを有効にするかの判定
    getNextLinkStatus() {
      return (1 < this.totalPageCount && this.currentPage < this.totalPageCount);
    },

    // ページネーションに表示するページ数の表示
    getDispPages() {
      let from = -1;
      let to = -1;
      const pages = [];

      if (this.totalPageCount <= this.dispPageCount) {
        // 全ページ数が表示ページ数以下の場合、全ページ表示
        from = 1;
        to = this.totalPageCount;
      } else {
        // 真ん中のページより前に表示するページ数を取得。
        // 例えば表示ページ数=5の場合、ページネーションは「1 2 3 4 5」となり、
        // 真ん中のページ(3)より前に表示するページ数は2になる
        const leastPages = Math.ceil(this.dispPageCount / 2) - 1;
        if (this.currentPage <= leastPages) {
          // 現ページが真ん中より前の場合、1ページから表示ページ数分表示
          from = 1;
          to = this.dispPageCount;
        } else if ((this.totalPageCount - leastPages) < this.currentPage) {
          // 現ページが最終ページ - 真ん中のページより前に表示するページ数より大きい場合、
          // 最終ページが末尾になるように表示ページ数分表示
          // 例えば表示ページ数=5、ページ数=50の場合、現ページが49, 50の場合が該当する
          from = this.totalPageCount - this.dispPageCount + 1;
          to = this.totalPageCount;
        } else {
          // 上記いずれでもない場合、現ページが真ん中になるように表示ページ数分表示
          from = this.currentPage - leastPages;
          to = from + this.dispPageCount - 1;
        }
      }

      for (let i = from; i <= to; i++) {
        pages.push(i);
      }

      return pages;
    }
  },
  watch: {
    propsCurrentPage(newValue) {
      this.currentPage = newValue;
    }
  },
  methods: {
    // ページネーションリンクがクリックされた場合、呼び元画面に
    // クリックされたページを渡す
    pageSelected(page) {
      this.currentPage = page;
      this.$emit("emitted", page);
    }
  }
};
</script>

<style scoped>
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.pagination > .previous, .pagination > .next {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.pagination .page {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
}

.pagination .page-disabled {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: rgba(0,0,0,.38);
}

</style>
