import * as types from "@/store/mutation-types";

const state = {
  message: "",
};

const mutations = {

  [ types.ERROR_SET ]( state, message ) {
    state.message = message;
  },

  [ types.ERROR_RESET ]( state ) {
    state.message = "";
  },
};

const actions = {
  setError({ commit }, payload ) {
    commit( types.ERROR_SET, payload );
  },
  resetErrors({ commit }) {
    commit( types.ERROR_RESET );
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};