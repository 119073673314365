export const initData  = {
  "id": null,
  "slug": "",
  "status": "draft",
  "question": "",
  "memo": "",
  "start_date": "",
  "end_date": "",
  "is_no_end_date": 1,
  "application_limit_count": 0,
  "string_color_index": 0,
  "string_color_rgb": "#000000",
  "background_color_index": 1,
  "background_color_rgb": "#FFFFFF",
  "accent_color_index": 3,
  "accent_color_rgb": "#fc5a33",
  "pattern": 1,
  "header_medium_content_id": null,
  "header_text": "",
  "privacy_policy_description": "下記リンクからプライバシーポリシーをご確認頂き、同意いただける方は回答するボタンをクリックして下さい。",
  "questionnaires": [],
  "privacy_policy_url": "",
  "privacy_policy_text": "プライバシーポリシー",
  "send_button_text": "回答する",
  "copyright": "",
  "before_open_message": "もうすぐ公開されます。",
  "after_closed_message": "このアンケートは終了しました。",
  "answered_message": "このアンケートはすでに回答しています。",
  "cta_button_link": "",
  "cta_button_message": "閉じる",
  "already_answered_message": "回答ありがとうございました。",
  "updated_at": "",
  "created_at": "",
  "header_medium_content": {},
  "incentive": {},
  "trigger_message": null,
};