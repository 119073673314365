import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

Vue.use( Vuex );

// ./modules/のファイルを取得してstoreを作成
const moduleFiles = require.context( "./modules", false, /\.js$/ );

const modules = moduleFiles.keys().reduce(( modules, modulePath ) => {
  const moduleName = modulePath.replace( /^\.\/(.*)\.\w+$/, "$1" );
  const value = moduleFiles( modulePath );
  if ( !value.default.namespaced ) {
    value.default.namespaced = true;
  }
  modules[ moduleName ] = value.default;
  return modules;
}, {});

export default new Vuex.Store({
  modules,
});